import React, { useEffect, useState } from "react";
import MainServices from "../../../../services/MainServices";
import PageLoader from "../../../../assets/loaders/PageLoading";
// import "./AddIDCMatrix.scss";
import { CustomInput } from "react-bootstrap";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import ArrowBack from "../../../../assets/images/icons/arrow-left.svg";
import Cross from "../../../../assets/images/icons/cross.svg";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../../assets/FormElements";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import ItemsDroppable from "../../../../assets/dragDrop/ItemsDroppable";
import EachDragItem from "../../../../assets/dragDrop/EachDragItem";
import { Modal, Form, InputGroup } from "react-bootstrap";
import * as Yup from "yup";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../../assets/dnd/StrictModeDroppable";
import { squareBackIcon } from "../../../../assets/images/icons/icons";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import TimingFunctions from "../../../../assets/functions/TimingFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../../assets/functions/filters";
import searchicon from "../../../../assets/images/icons/search-normal.svg";
import useFeatureSetting from "../../../../hooks/useFeatureSetting";
import useAuth from "../../../../hooks/useAuth";

export default function AddWorkflowGroup() {
  const [filters, setFilters] = useState({});
  const { deBounce } = TimingFunctions();
  const { getEmployeeMasters } = MainServices();
  const [pageLoading, setPageLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [workflowModal, setWorkflowModal] = useState(false);
  const {
    getWorkflowGroupById,
    getIDCMasters,
    updateWorkflowGroup,
    addWorkflowGroup,
  } = MainServices();
  const [selectedIDCMatrix, setSelectedIDCMatrix] = useState();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [disabled, setDisabled] = useState("No");
  const { updateSettingContext, setting } = useFeatureSetting();
  const { user } = useAuth();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    if (publicId) {
      getIDCMastersById();
    }
  }, [publicId]);

  useEffect(() => {
    updateSettingContext("workflow_group");
  }, []);

  const addIDCSchema = Yup.object().shape({
    workflowGroupName: Yup.string().required("WorkFlow Group is Required"),
    workflows: Yup.mixed().required("WorkFlows is Required"),
  });

  const updateIDCSchema = Yup.object().shape({
    workflowGroupName: Yup.string().required("WorkFlow Group is Required"),
    workflows: Yup.mixed().required("WorkFlows is Required"),
    password: Yup.string().required("Password is Required"),
  });

  useEffect(() => {
    console.log(changedParams);
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);
    let pagination = {
      limit: changedParams?.limit || 20,
      page: changedParams?.page || 1,
    };
    getEmployeesByDiscipline({ value: "all", filters });
  }, [changedParams.filters, searchString]);

  const onManageWorkflow = () => {
    setWorkflowModal((prev) => !prev);
  };

  //apis
  const getEmployeesByDiscipline = async ({ filters, page, limit }) => {
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };
    getEmployeeMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.empName,
            value: each.publicId,
          }));

          setEmployees(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateIDC = async (values) => {
    console.log(values);
    setButtonLoading(true);
    const details = {
      workflowGroupName: values.workflowGroupName,
      workflowData: values.workflows,
      source: "auth",
      disabled: values.disabled === true ? "No" : "Yes",
      ...(values?.password && { password: values?.password }),
    };
    await axios
      .all([
        updateWorkflowGroup(
          details,
          selectedIDCMatrix?.publicId,
          selectedIDCMatrix?.concurrencyStamp
        ),
      ])
      .then((res) => {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("WorkFlow Updated Successfully");
            navigate(-1);
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Workflow Master");
      });
  };

  const getIDCMastersById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getWorkflowGroupById(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let IDCMasters = resData.data ? resData.data : {};
            setSelectedIDCMatrix(IDCMasters);
            onManageWorkflow();
            setPageLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Workflow Group");
      });
  };

  const addWorkflowGroupMaster = async (values) => {
    setButtonLoading(true);
    const details = {
      workflowGroupName: values.workflowGroupName,
      workflowData: values.workflows,
      source: "auth",
      disabled: values.disabled === true ? "No" : "Yes",
      ...(values?.password && { password: values?.password }),
    };
    await axios
      .all([addWorkflowGroup(details)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("WorkFlow Add Successfully");
            navigate(-1);
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Workflow Master");
      });
  };
  //functions
  const onDragItem = ({ item, setFieldValue, workflow, values }) => {
    let workflows = values.workflows || [];
    workflows = workflows.map((eachW) => {
      if (eachW.workflow === workflow) {
        let users = eachW.users || [];
        let match = users.find((each) => each.value === item.value);
        if (!match) {
          users.push(item);
        }
        return {
          ...eachW,
          users,
        };
      }
      return eachW;
    });
    console.log(workflows);
    setFieldValue(workflows);
  };

  const searchemployee = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      empName: { empName: e.target.value },
    });
    let search = filtersToUrlParams({
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const onDragEnd = (result, set, values) => {
    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) {
      return;
    }

    // If the drag type is 'SECTION'

    const update_values = reorder(
      values.workflows,
      source.index,
      destination.index
    );
    set("workflows", update_values);

    // If the drag type is 'ITEM' within the same section
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="addidcmatrix-main">
          <Formik
            initialValues={{
              workflow_name: selectedIDCMatrix?.name || "",
              workflowGroupName: selectedIDCMatrix?.workflowGroupName || "",
              workflows: selectedIDCMatrix?.workflowData || [
                {
                  workflow: "reviewer",
                  users: [],
                  noOfApprovals: "",
                },
                {
                  workflow: "approver",
                  users: [],
                  noOfApprovals: "",
                },
              ],
              disabled:
                selectedIDCMatrix?.disabled === false ||
                selectedIDCMatrix?.disabled === "No"
                  ? true
                  : false,
              email: user?.email || "",
              password: "",
            }}
            onSubmit={(values) => {
              selectedIDCMatrix
                ? updateIDC(values)
                : addWorkflowGroupMaster(values);
            }}
            validationSchema={
              setting.digital_signature && selectedIDCMatrix
                ? updateIDCSchema
                : addIDCSchema
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <div className="workflow-manager">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3">
                      <div className="back-button">
                        <img
                          src={ArrowBack}
                          alt=""
                          onClick={() => navigate(-1)}
                        />
                      </div>
                      <p
                        className="p-0 content-header-main"
                        style={{ fontWeight: "700", fontSize: "24px" }}
                      >
                        ADD WorkFlow
                      </p>
                    </div>
                    {/* <CustomInput
                      type="switch"
                      id="disabled"
                      name="disabled"
                      checked={values?.disabled}
                      onChange={(e) =>
                        setFieldValue("disabled", e.target.checked)
                      }
                    /> */}

                    <label className="switch">
                      <input
                        type="checkbox"
                        id="disabled"
                        name="disabled"
                        checked={values?.disabled}
                        onChange={(e) => {
                          setFieldValue("disabled", e.target.checked);
                          // updateIDC(values);
                        }}
                      />

                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="form-group">
                      <label className="form-label" for="passing_percentage">
                        Workflow Group Name
                      </label>
                      <div className="form-control-wrap">
                        <PrimaryInput
                          type="text"
                          className="form-control"
                          id="workflowGroupName"
                          name="workflowGroupName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.workflowGroupName}
                          placeholder="Enter Workflow Group Name"
                          style={{ width: "400px" }}
                        />

                        <CustomErrorMessage
                          name="workflowGroupName"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <Button
                      onClick={onManageWorkflow}
                      className="theme-btn-main mt-4"
                    >
                      Manage WorkFlow
                    </Button>
                  </div>
                </div>
                <hr />

                <div className="workflows-list">
                  {values.workflows &&
                    values.workflows.length > 0 &&
                    values.workflows.map((each_work, index) => {
                      // Generate the options array for the number of approvals
                      const options = Array.from(
                        {
                          length:
                            each_work?.users &&
                            each_work?.users.length > 0 &&
                            each_work?.users.length,
                        },
                        (_, i) => ({ label: `${i + 1}`, value: i + 1 })
                      );

                      return (
                        <div className="each-work-flow mt-3" key={index}>
                          <div className="d-flex justify-content-between">
                            <div className="workflow-head">
                              <span style={{ textTransform: "capitalize" }}>
                                {each_work.workflow}
                              </span>
                            </div>
                            <div className="form-group mb-1 d-flex gap-2 align-items-center">
                              <label
                                className="form-label mb-0"
                                htmlFor="category"
                              >
                                {`Select Number of ${each_work.workflow}`}
                                <span style={{ color: "#f00" }}> *</span>
                              </label>
                              <div className="form-control-wrap">
                                <Select
                                  options={options}
                                  className="sop-custom-form-select"
                                  id={`noOfApprovals_${index}`}
                                  name={`noOfApprovals_${index}`}
                                  placeholder="Select"
                                  onChange={(option) => {
                                    const selectedLabel = option
                                      ? option.label
                                      : "";
                                    const updatedWorkflows = [
                                      ...values.workflows,
                                    ];
                                    updatedWorkflows[index] = {
                                      ...updatedWorkflows[index],
                                      noOfApprovals: selectedLabel,
                                    };
                                    setFieldValue(
                                      "workflows",
                                      updatedWorkflows
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={options.find(
                                    (option) =>
                                      option.label === each_work.noOfApprovals
                                  )}
                                />
                                <CustomErrorMessage
                                  name={`noOfApprovals_${index}`}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="users mt-2 px-3">
                            {each_work.users &&
                              each_work.users.length > 0 &&
                              each_work.users.map((user, subI) => (
                                <div
                                  className="each-user d-flex justify-content-between mt-2"
                                  key={subI}
                                >
                                  <div>
                                    <span>{subI + 1}.</span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {user.label}
                                    </span>
                                  </div>
                                  <div>
                                    <img
                                      alt="Cross"
                                      src={Cross}
                                      className="delete-topic"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let data = values?.workflows || [];
                                        let users = data[index].users || [];
                                        users = users.filter(
                                          (each, i) => subI !== i
                                        );
                                        data[index].users = users;
                                        setFieldValue("workflows", data);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {values.workflows &&
                  values.workflows.length > 0 &&
                  values.workflows[0]?.users &&
                  values.workflows[0]?.users.length === 0 && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "white",
                        padding: "5px",
                      }}
                    >
                      Will Appear Here
                    </div>
                  )}

                <hr />

                {setting.digital_signature && selectedIDCMatrix && (
                  <div className="row mb-5">
                    <div className="form-group mt-3 col-6">
                      <label className="mx-3 mb-1">Email</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                          type="text"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-6">
                      <label className="mx-3 mb-1">Password</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          type="password"
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="password"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-end gap-3 mt-3">
                  <button
                    className="col-2 btn btn-primary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary col-2"
                    onClick={handleSubmit}
                  >
                    {buttonLoading ? (
                      <ButtonLoading />
                    ) : selectedIDCMatrix ? (
                      "Update WorkFlow"
                    ) : (
                      "Add WorkFlow "
                    )}
                  </button>
                </div>

                <Modal
                  centered
                  show={workflowModal}
                  onHide={() => onManageWorkflow()}
                  className="add-department-modal-main"
                  size="xl"
                >
                  <Modal.Header
                    closeButton
                    className="add-department-modal-header"
                  >
                    <Modal.Title className="add-department-modal-title">
                      Manage Workflow
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="add-department-modal-body">
                    <div className="admin-popular-main">
                      <div className="d-flex justify-content-end">
                        <InputGroup
                          style={{
                            width: "auto",
                            border: "0.5px solid #000",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control
                            placeholder="Search User"
                            aria-label="Search"
                            aria-describedby="search"
                            className="search-input-main"
                            onChange={(e) => searchemployee(e)}
                          />
                          <InputGroup.Text
                            id="search"
                            className="search-input-main"
                          >
                            <img src={searchicon} alt="search icon" />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        workflows: values.workflows || [],
                        workflow: "",
                      }}
                      onSubmit={(m_values) => {
                        setFieldValue("workflows", m_values.workflows);
                        onManageWorkflow();
                      }}
                      key=""
                    >
                      {({
                        handleSubmit: m_submit,
                        setFieldValue: m_set,
                        values: m_values,
                        handleChange: m_change,
                        handleBlur: m_blur,
                      }) => (
                        <>
                          <div>
                            <div className="form-group my-2">
                              <label
                                className="form-label"
                                for="passing_percentage"
                              >
                                Workflow Name
                              </label>
                              <div className="form-control-wrap">
                                <div className="d-flex gap-2 ">
                                  <PrimaryInput
                                    type="text"
                                    className="form-control"
                                    id="workflow"
                                    name="workflow"
                                    onChange={m_change}
                                    onBlur={m_blur}
                                    value={m_values.workflow}
                                    placeholder="Enter"
                                  />
                                  <Button
                                    onClick={() => {
                                      let workflows = m_values.workflows || [];
                                      let data = m_values.workflows || [];
                                      if (m_values.workflow) {
                                        let isthere = false;
                                        data.forEach((each) => {
                                          if (
                                            m_values.workflow.toLowerCase() ===
                                            each.workflow.toLowerCase()
                                          ) {
                                            isthere = true;
                                          }
                                        });
                                        if (!isthere) {
                                          workflows.push({
                                            workflow:
                                              m_values.workflow.toLowerCase(),
                                            users: [],
                                          });
                                        }
                                      }
                                      m_set("workflows", workflows);
                                    }}
                                  >
                                    Add
                                  </Button>
                                </div>
                                <CustomErrorMessage
                                  name="passing_percentage"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex gap-2"
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{
                                width: "49%",
                                height: 500,
                                overflowY: "scroll",
                              }}
                            >
                              <h6 className="mt-3">WorkFlows</h6>
                              <div className="d-flex gap-2 mb-3">
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    onDragEnd(result, m_set, m_values)
                                  }
                                >
                                  <StrictModeDroppable
                                    droppableId="workflows"
                                    direction="vertical"
                                  >
                                    {(sectionsProvided) => (
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                        {...sectionsProvided.droppableProps}
                                        ref={sectionsProvided.innerRef}
                                      >
                                        {m_values.workflows.map(
                                          (section, sectionIndex) => (
                                            <Draggable
                                              key={section.workflow}
                                              draggableId={section.workflow}
                                              index={sectionIndex}
                                              style={{ width: "100%" }}
                                            >
                                              {(sectionProvided) => (
                                                <div
                                                  {...sectionProvided.draggableProps}
                                                  {...sectionProvided.dragHandleProps}
                                                  ref={sectionProvided.innerRef}
                                                >
                                                  <ItemsDroppable
                                                    onDrag={(props) =>
                                                      onDragItem({
                                                        ...props,
                                                        workflow:
                                                          section.workflow,
                                                        values: m_values,
                                                      })
                                                    }
                                                    setFieldValue={m_set}
                                                    dataType="Available"
                                                  >
                                                    <div className="each-section my-3">
                                                      <div className="d-flex justify-content-between">
                                                        <h6
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {sectionIndex + 1}.
                                                          {section.workflow}
                                                        </h6>
                                                        <div>
                                                          <img
                                                            alt="Cross"
                                                            src={Cross}
                                                            className="delete-topic"
                                                            onClick={() => {
                                                              let data =
                                                                m_values.workflows ||
                                                                [];
                                                              data =
                                                                data.filter(
                                                                  (each, i) =>
                                                                    i !==
                                                                    sectionIndex
                                                                );
                                                              m_set(
                                                                "workflows",
                                                                data
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="users mt-2 px-3">
                                                        {section.users &&
                                                          section.users.length >
                                                            0 &&
                                                          section.users.map(
                                                            (user, subI) => (
                                                              <div className="each-user d-flex justify-content-between mt-2">
                                                                <div>
                                                                  <span>
                                                                    {subI + 1}.
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                  >
                                                                    {user.label}
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <img
                                                                    alt="Cross"
                                                                    src={Cross}
                                                                    className="delete-topic"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      let data =
                                                                        m_values?.workflows ||
                                                                        [];
                                                                      let users =
                                                                        data[
                                                                          sectionIndex
                                                                        ]
                                                                          .users ||
                                                                        [];
                                                                      users =
                                                                        users.filter(
                                                                          (
                                                                            each,
                                                                            i
                                                                          ) =>
                                                                            subI !==
                                                                            i
                                                                        );
                                                                      data[
                                                                        sectionIndex
                                                                      ].users =
                                                                        users;
                                                                      m_set(
                                                                        "workflows",
                                                                        data
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  </ItemsDroppable>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {sectionsProvided.placeholder}
                                      </div>
                                    )}
                                  </StrictModeDroppable>
                                </DragDropContext>
                              </div>
                            </div>

                            <div className="available" style={{ width: "49%" }}>
                              <ItemsDroppable
                                onDrag={onDragItem}
                                setFieldValue={setFieldValue}
                                dataType="Available"
                                style={{ height: 500 }}
                              >
                                <h5>Users</h5>
                                {employees &&
                                  employees.length > 0 &&
                                  employees.map((_, index) => (
                                    <EachDragItem props={_} type="availble" />
                                  ))}
                              </ItemsDroppable>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex gap-2">
                              <Button
                                className="back-icon-btn-main"
                                onClick={() => {
                                  onManageWorkflow();
                                }}
                              >
                                <span>{squareBackIcon}</span>
                                <span className="ms-2">Back</span>
                              </Button>
                              <Button
                                className="theme-btn-main"
                                onClick={m_submit}
                              >
                                {buttonLoading ? <ButtonLoading /> : "Submit"}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
