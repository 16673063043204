import { Modal } from "react-bootstrap";
import "./SuccessModal.scss"
import React from 'react';
import successimage from "../images/icons/success.svg";

const SuccessModal = ({modal, setModal, message, type}) => {
  return (
    <div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="md"
        className="template-modal"
        centered
      >
        <Modal.Body>
            <div className="success-container me-auto">
                <img className="me-auto" src={successimage} alt="success" />
                <p>{message}</p>
                <div>{type} Successfully!</div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SuccessModal