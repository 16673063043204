import "./Header.scss";
import React, { useEffect, useState } from "react";
import useCurrentPage from "../../hooks/useCurrentPage";
import { Dropdown } from "react-bootstrap";
import { notificationicon } from "../../assets/images/icons/icons";
import headerprofileicon from "../../assets/images/icons/header-profile.svg";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import MainServices from "../../services/MainServices";
import axios from "axios";
import SimpleBar from "simplebar-react";
import Close from "../../assets/images/icons/cross.svg";

//images
export default function Header({ setSidebarToggle }) {
  const { currentPage } = useCurrentPage();
  const [isNotificationsOpen, setNotificationOpen] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  const { getRolePermissionsByRoles, getToolsAccess } = MainServices();
  const [toolsIcon, setToolsIcons] = useState(0);

  const toggleSideBar = () => {
    setSidebarToggle((oldValue) => !oldValue);
  };

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const onNotification = () => {
    setNotificationOpen(!isNotificationsOpen);
  };

  useEffect(() => {
    // getRolePermissions();
    getToolMasterByStatus();
  }, []);

  //api
  const getRolePermissions = async () => {
    const details = {
      source: "auth",
    };
    await axios
      .all([getRolePermissionsByRoles(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            // let rolePermissions = resData.data ? resData.data : [];
            // rolePermissions = rolePermissions.data ? rolePermissions.data : {};
            // setRolePermissions(rolePermissions);
            // updateUserContext(rolePermissions);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  const getToolMasterByStatus = async (filters) => {
    const details = {
      filters: [{ key: "name", eq: "auth" }],
    };
    await axios
      .all([getToolsAccess(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let toolsIcon = resData.data ? resData.data : [];
            toolsIcon = toolsIcon.map((_, index) => {
              return {
                ..._,
              };
            });
            if (toolsIcon.length > 0) {
              changeFavicon(toolsIcon[0].toolLogo);
            }
            setToolsIcons(toolsIcon);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Location");
      });
  };

  const changeFavicon = (toolsIcon) => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = toolsIcon;
    document.getElementsByTagName("head")[0].appendChild(link);
  };
  return (
    <div className="header-main">
      <div className="nk-header is-light">
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ms-n1">
              <div
                onClick={toggleSideBar}
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu"></em>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center pe-3">
              <div>
                <p className="m-0">
                  <b>{currentPage?.title} Management</b>{" "}
                  {currentPage.subtitle ? "/ " : ""}
                  {currentPage?.subtitle}
                </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div
                  className="notification-icon-main"
                  onClick={onNotification}
                  style={{ cursor: "pointer" }}
                >
                  <div>{notificationicon}</div>
                </div>
                <Dropdown className="dropdown-main">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="dropdown-content-main"
                  >
                    <div className="d-flex align-items-center">
                      <div className="header-profile-icon-main">
                        <img src={headerprofileicon} alt="profile icon" />
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <p className="m-0">{user?.name}</p>
                        <p className="m-0">{user?.email}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        navigate({
                          pathname: "/profile",
                          search: `?user_id=${user.user_id}`,
                        })
                      }
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onLogout}>Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div
          class={`toggle-slide-right ${
            isNotificationsOpen ? "slide-right-open" : "slide-right-close"
          }`}
          id="mobile-menu"
          style={{
            overflowY: "scroll",
            position: "fixed",
            width: "450px",
          }}
        >
          <div className="notifications">
            <div className="notifications-head d-flex justify-content-between align-items-center">
              <h6>Notifications</h6>
              <div
                onClick={() => onNotification("")}
                className="notification-close mx-3"
              >
                <img src={Close} alt="list-icon" />
              </div>
            </div>
            <SimpleBar className="notifications-content">
              {[...Array(20)].map((_) => (
                <div className="each-notification">
                  <p className="notification-title">Title</p>
                  <p className="notification-info">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <p className="notification-time">5min Ago</p>
                  <hr />
                </div>
              ))}
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  );
}
