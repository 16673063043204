import React, { useEffect } from "react";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { Formik } from "formik";
import { Form, Button } from "react-bootstrap";
import ButtonLoading from "../../assets/loaders/ButtonLoading";
import { PrimaryInput, PasswordInput } from "../FormElements";
import { squareBackIcon } from "../../assets/images/icons/icons";
import CustomErrorMessage from "../elements/ErrorMessage";
import Select from "react-select";
import useFeatureSetting from "../../hooks/useFeatureSetting";

const statusSchema = Yup.object().shape({
  status: Yup.mixed().required("Status  is Required"),
  user_id: Yup.string().required("User Id is required"),
  comment: Yup.string().required("Comment is required"),
});

const statusPasswordSchema = Yup.object().shape({
  status: Yup.mixed().required("Status  is Required"),
  user_id: Yup.string().required("User Id is required"),
  password: Yup.string().required("Password is required"),
  comment: Yup.string().required("Comment is required"),
});

export default function ApprovalStatusModal({
  statusApiFunction,
  onClose,
  loading,
}) {
  const { user } = useAuth();
  const { updateSettingContext, setting } = useFeatureSetting();

  useEffect(() => {
    updateSettingContext("event_workflow");
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          status: "",
          password: "",
          user_id: user.email,
          comment: "",
        }}
        validationSchema={
          setting.digital_signature ? statusPasswordSchema : statusSchema
        }
        onSubmit={(values) => {
          statusApiFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <div className="form-group mt-3">
              <label className="form-label" for="question">
                Status
                <span className="required-star"> *</span>
              </label>
              <Select
                className="sop-custom-form-select"
                id="topic"
                name="status"
                placeholder="Select Status"
                onChange={(e) => {
                  setFieldValue("status", e);
                }}
                onBlur={handleBlur}
                value={values.status}
                options={[
                  {
                    label: "Approved",
                    value: "approved",
                  },
                  {
                    label: "Rejected",
                    value: "rejected",
                  },
                ]}
              />
            </div>
            <CustomErrorMessage
              name="status"
              errors={errors}
              touched={touched}
            />
            {setting.digital_signature && (
              <>
                <div class="form-group mt-3">
                  <label className="form-label" for="user_id">
                    User Id
                    <span className="required-star"> *</span>
                  </label>
                  <div class="form-control-wrap">
                    <PrimaryInput
                      class="form-control"
                      id="user_id"
                      name="user_id"
                      placeholder="User Id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_id}
                      disabled
                    />

                    <CustomErrorMessage
                      name="user_id"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label className="form-label" for="password">
                    Password
                    <span className="required-star"> *</span>
                  </label>
                  <div class="form-control-wrap">
                    <PasswordInput
                      class="form-control"
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <CustomErrorMessage
                      name="password"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="form-group mt-3">
              <label className="form-label" for="comment">
                Comment
                <span className="required-star"> *</span>
              </label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control employee-custom-form-control"
                  placeholder="Enter Comment"
                  aria-label="With textarea"
                  name="comment"
                  id="comment"
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <CustomErrorMessage
                  name="comment"
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className="mt-3">
              <div className="d-flex gap-2 justify-content-end">
                <Button onClick={onClose} className="back-icon-btn-main">
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <Button type="submit" className="theme-btn-main">
                  {loading ? <ButtonLoading /> : "Submit"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
