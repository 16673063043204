import React, { useState, useEffect } from "react";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Table } from "reactstrap";
import IconButton from "../../../assets/elements/IconButton";
import Pagination from "../../../assets/elements/CustomPagination";
import SuccessModal from "../../../assets/elements/SuccessModal";
import "./securityGroup.scss";
import { InputGroup, Form, Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import useCurrentPage from "../../../hooks/useCurrentPage";
import accessRole_reset from "../../../assets/images/icons/reseticon.svg";
import axios from "axios";
import PageLoader from "../../../assets/loaders/PageLoading";
import MainServices from "../../../services/MainServices";
import { notifyWarn, notifySuccess } from "../../../assets/elements/Toast";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { PrimaryInput } from "../../../assets/FormElements";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import Select from "react-select";
import { PrimaryButton } from "../../../assets/buttons";
import useFeatureSetting from "../../../hooks/useFeatureSetting";
import StatusModalContent from "../../../assets/modal/StatusModalContent";
import editgreenicon from "../../../assets/images/icons/edit1.svg";
import useAuth from "../../../hooks/useAuth";
import Cross from "../../../assets/images/icons/cross.svg";
import IsWorkflowSlug from "../../../assets/modal/IsWorkflowSlug";

const allStatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const staticData = [
  { role: "Admin", description: "Administrator role", createdAt: "2024-02-29" },
  { role: "User", description: "Regular user role", createdAt: "2024-02-28" },
];

const SecurityGroup = () => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState();
  const [successType, setSuccessType] = useState();
  const [editAccessRoleModal, setEditAccessRoleModal] = useState(false);
  const [deleteAccessRoleModal, setDeleteAccessRoleModal] = useState(false);
  const [accessRoles, setAccessRoles] = useState([]);
  const [totalAccessRoles, setTotalAccessRoles] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const { updateCurrentPage } = useCurrentPage();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: "All",
  });
  const location = useLocation();
  const [selectedSecurityGroupName, setSelectedSecurityGroupName] = useState();
  const [securityGroupName, setSecurityGroupName] = useState([]);
  const [isWorkflowFlag, setIsWorkflowFlag] = useState({});
  const [workflowModal, setWorkflowModal] = useState(false);
  const [workflowDataSet, setWorkflowDataSet] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  const { deBounce } = TimingFunctions();
  const { grantPermission } = PermissionFunctions();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({});
  const { user } = useAuth();
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const { updateSettingContext, setting } = useFeatureSetting();
  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const [statusModal, setStatusModal] = useState(false);

  const accessRoleSchema = Yup.object().shape({
    securityGroupName: Yup.string()
      .transform((value) => value.trim())
      .required("Securit Group Name Required"),
    departmentId: Yup.object().required("Department must be an object"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Description Is Required"),
  });

  const updateAccessRoleSchema = Yup.object().shape({
    securityGroupName: Yup.string()
      .transform((value) => value.trim())
      .required("Securit Group Name Required"),
    departmentId: Yup.object().required("Department must be an object"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Description Is Required"),
    password: Yup.string()
      .transform((value) => value.trim())
      .required("Password is Required"),
    workflows: Yup.array()
      .of(
        Yup.object().shape({
          users: Yup.array().required('users array is required'),
        })
      )
      .test('at-least-one-user', 'Workflow is Required', function (workflows) {
        return workflows.some(workflow => workflow.users && workflow.users.length > 0);
      })
  });

  const deleteSchema = Yup.object().shape({
    password: Yup.string()
      .transform((value) => value.trim())
      .required("Password is Required"),
  });

  const {
    addSecurityGroupMaster,
    getSecurityGroupMaster,
    getDepartmentMasters,
    updateSecurityGroupMaster,
    deleteSecurityGroupMaster,
    changeSecurityGroupStatusMaster,
    getWorkflowsBySlug,
  } = MainServices();

  useEffect(() => {
    updateCurrentPage({
      title: "Role",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //  getSecurityGroup();
    getDepartments([
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ]);
    getIsWorkflowSlug();
    // getTools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSettingContext("security-group");
  }, []);

  const getDepartments = async (filters, search) => {
    const details = {
      filters,
      // status: "active",
    };
    let results = await axios
      .all([getDepartmentMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData?.data;
            departments = departments.map((each) => {
              return {
                ...each,
                label: each?.deptName,
                value: each?.publicId,
              };
            });
            !search && setDocumentDepartments(departments);
            return departments;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
    return results;
  };

  const getSecurityGroup = async () => {
    const details = {};
    await axios.all([getSecurityGroupMaster(details)]).then(function (res) {
      if (res[0]) {
        let resData = res[0]?.data || [];
        resData = resData.map((data, index) => {
          return {
            ...data,
            SNo: index + 1 + (pagination.page - 1) * pagination.limit,
          };
        });
        setSecurityGroupName(resData);
      }
    });
  };

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    // setSelectedStatus(filtersObj?.status?.status || "");
    setSearchString(filtersObj?.name?.name);
    setFilters(filtersObj);

    getSecurityGroupMastersByStatus({
      page: pagination.page,
      limit: pagination.limit,
      filters,
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  useEffect(() => {
    let selectOptions = [{ value: "all", label: "all", key: "all" }];
    let available = setting.available_status || [];
    available.map((each) => {
      selectOptions.push({ value: each, label: each, key: "status" });
    });
    let record = setting.record_status || [];
    record.map((each) => {
      selectOptions.push({ value: each, label: each, key: "record_status" });
    });
    setSelectOptions(selectOptions);
  }, [setting]);

  // const onAddSecurityGroup = (selected) => {
  //   if (selected) {
  //     setselectedEmployee(selected);
  //   } else {
  //     setselectedEmployee("");
  //   }
  //   setaddEmployeeModal(!addEmployeeModal);
  // };

  const onAddSecurityGroup = (selected) => {
    setEditAccessRoleModal(!editAccessRoleModal);
    if (selected) {
      const updatedSelected = {
        ...selected,
        deptartment: {
          ...selected.deptartment,
          label: selected.deptartment.deptName,
          value: selected.deptartment.publicId,
        },
      };
      setSelectedSecurityGroupName(updatedSelected);
    }
  };

  //apis
  // const getPermissionsByRole = async (filters) => {
  //   setModalLoading(true);
  //   const details = {
  //     filters,
  //     pageNumber: 1,
  //     pageSize: 100,
  //   };
  //   await axios
  //     .all([getRolePermissionMasters(details)])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         if (resData.success) {
  //           let rolePermissions = resData.data ? resData.data : [];
  //           rolePermissions = rolePermissions.map((_) => _.permissionId);
  //           setRolePermissions(rolePermissions);
  //           setModalLoading(false);
  //         } else {
  //           setModalLoading(false);
  //         }
  //       } else {
  //         setModalLoading(false);
  //       }
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       if (res.message)
  //         console.log("An error occurred in getting Permissions");
  //     });
  // };

  // const getPermissionsList = async (tool) => {
  //   setModalLoading(true);
  //   const details = {
  //     ...(tool ? { filters: [{ key: "source", eq: tool }] } : {}),
  //   };
  //   await axios
  //     .all([getAllPermission(details)])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         if (resData.success) {
  //           let roles = resData.data ? resData.data : {};
  //           roles = roles.reduce(
  //             (result, item) => ({
  //               ...result,
  //               [item?.operationId || undefined]: {
  //                 ...item?.operation,
  //                 permissions: [
  //                   ...(result[item?.operationId]?.permissions || []),
  //                   {
  //                     ...item,
  //                     checked: false,
  //                   },
  //                 ],
  //               },
  //             }),
  //             {}
  //           );
  //           let modifedPermissions = Object.keys(roles).map((key) => {
  //             return {
  //               ...roles[key],
  //             };
  //           });
  //           setPermissionMenu(modifedPermissions);
  //         } else {
  //         }
  //       } else {
  //       }
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       if (res.message)
  //         console.log("An error occurred in getting Permissions");
  //     });
  // };

  const getSecurityGroupMastersByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 20,
      pageNumber: page || 1,
      filters,
    };
    await axios
      .all([getSecurityGroupMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let securityGroupName = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;

            setTotalAccessRoles(total);
            securityGroupName = securityGroupName.map((_, index) => {
              return {
                ..._,
                sNo: rowsPerPage * (page - 1) + index + 1,
                label: _?.securityGroupName,
                value: _?.publicId,
              };
            });
            setSecurityGroupName(securityGroupName);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Roles");
      });
  };

  // const addRolePermission = async (values) => {
  //   setButtonLoading(true);
  //   let permissions = values?.permissions || [];
  //   permissions = permissions.map((_) => {
  //     return {
  //       permissionId: _,
  //       status: "active",
  //       source: values.source,
  //     };
  //   });

  //   const details = {
  //     role: values?.role_name,
  //     permissions: permissions,
  //     source: values.source,
  //   };
  //   await axios
  //     .all([addRolePermissionMaster(details)])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         if (resData.success) {
  //           getRolePermissionMastersByStatus({
  //             page: pagination.page,
  //             limit: pagination.limit,
  //             filters,
  //           });
  //           handleEditClick();
  //           notifySuccess("Security Group Added Successfully");
  //           setButtonLoading(false);
  //         } else {
  //           setButtonLoading(false);
  //         }
  //       }
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       if (res.message)
  //         console.log("An error occurred in adding Security Group");
  //     });
  // };

  // const updateRolePermission = async (values) => {
  //   setButtonLoading(true);
  //   let permissions = values?.permissions || [];
  //   permissions = permissions.map((_) => {
  //     return {
  //       permissionId: _,
  //       status: "active",
  //       roleId: selectedAccessRole?.publicId,
  //       source: values.source,
  //     };
  //   });

  //   const details = {
  //     permission_data: permissions,
  //     roleId: selectedAccessRole?.publicId,
  //     source: values.source,
  //   };

  //   await axios
  //     .all([
  //       updateRolePermissionMaster(
  //         details,
  //         selectedAccessRole.publicId,
  //         selectedAccessRole.concurrencyStamp
  //       ),
  //     ])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         let message = resData.message;
  //         if (resData.success) {
  //           getRolePermissionMastersByStatus({
  //             page: pagination.page,
  //             limit: pagination.limit,
  //             filters,
  //           });
  //           handleEditClick();
  //           notifySuccess("Security Group Updated Successfully");
  //           setButtonLoading(false);
  //         } else {
  //           if (message) notifyWarn(message);
  //           setButtonLoading(false);
  //         }
  //       } else {
  //         setButtonLoading(false);
  //       }
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       if (res.message)
  //         console.log("An error occurred in updating Security Group");
  //     });
  // };

  // const deleteRolePermission = async () => {
  //   setButtonLoading(true);
  //   const details = {
  //     status: "inactive",
  //   };
  //   await axios
  //     .all([
  //       deleteRolePermissionMaster(
  //         details,
  //         selectedAccessRole.publicId,
  //         selectedAccessRole.concurrencyStamp
  //       ),
  //     ])
  //     .then(function (res) {
  //       if (res[0]) {
  //         let resData = res[0];
  //         if (resData.success) {
  //           getRolePermissionMastersByStatus({
  //             page: pagination.page,
  //             limit: pagination.limit,
  //             filters,
  //           });
  //           handleDeleteClick();
  //           notifySuccess("Security Group Deleted Successfully");
  //           setButtonLoading(false);
  //         } else {
  //           setButtonLoading(false);
  //         }
  //       } else {
  //         setButtonLoading(false);
  //       }
  //     })
  //     .catch(function (res) {
  //       if (res.message)
  //         console.log("An error occurred in deleting Security Group");
  //     });
  // };

  //

  const handleEditClick = async (selected, tool) => {
    setEditAccessRoleModal(!editAccessRoleModal);
  };

  const onDeleteSecurityGroup = (selected) => {
    if (selected) {
      setSelectedSecurityGroupName(selected);
    } else {
      setSelectedSecurityGroupName("");
    }
    setDeleteAccessRoleModal(!deleteAccessRoleModal);
  };

  const handleCloseEditModal = (selected) => {
    if (selected) {
      setSelectedSecurityGroupName(selected);
    } else {
      setSelectedSecurityGroupName("");
    }
    setEditAccessRoleModal(!editAccessRoleModal);
  };

  const onStatusChange = (e) => {
    let value = e.value;
    const { record_status, status, ...remaining } = filters;
    let mfilters = filtersToUri({
      ...remaining,
      ...(value === "all"
        ? {}
        : e.key === "record_status"
        ? {
            record_status: { record_status: value },
            status: { status: "approved" },
          }
        : { status: { status: value } }),
    });
    setSelectedStatus({ label: value, value: value });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchTraining = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      securityGroupName: { securityGroupName: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 20,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const addSecurityGroup = async (values) => {
    setUpdateLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      securityGroupName: values?.securityGroupName,
      description: values?.description,
      departmentId: values?.departmentId?.publicId,
      ...(values?.password && { password: values?.password }),
      status: "active",
    };
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([addSecurityGroupMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            getSecurityGroupMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            onAddSecurityGroup();
            notifySuccess("Security Group Added Successfully");
            setUpdateLoading(false);
          } else {
            message && notifyWarn(message);
            setUpdateLoading(false);
          }
        } else {
          setUpdateLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Plant Master");
      });
  };

  const updateSecurityGroup = async (values) => {
    setUpdateLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      securityGroupName: values?.securityGroupName,
      description: values?.description,
      departmentId: values?.departmentId?.publicId,
      ...(values?.password && { password: values?.password }),
    };
    const publicId = selectedSecurityGroupName.publicId;
    const concurrencyStamp = selectedSecurityGroupName.concurrencyStamp;
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([updateSecurityGroupMaster(details, publicId, concurrencyStamp)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getSecurityGroupMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            setUpdateLoading(false);
            onAddSecurityGroup();
          } else {
            message && notifyWarn(message);
            setUpdateLoading(false);
          }
        } else {
          setUpdateLoading(false);
        }
      })
      .catch((err) => {
        setUpdateLoading(false);
        console.error(err);
      });
  };

  const onSecurityGroupStatusChange = async (
    { status, email, password },
    isStatus
  ) => {
    setButtonLoading(true);
    const details = {
      status: status.value ? status.value : status,
      // email,
      ...(password && { password: password }),
    };
    await axios
      .all([
        changeSecurityGroupStatusMaster(
          details,
          selectedSecurityGroupName.publicId,
          selectedSecurityGroupName.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getSecurityGroupMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            setStatusModal(false);
            setDeleteAccessRoleModal(false);
            notifySuccess("Status updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in Status Update Master");
      });
  };

  const getIsWorkflowSlug = async () => {
    const details = {};
    await axios
      .all([getWorkflowsBySlug(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let isWorkflow = resData?.data ? resData?.data : {};
            isWorkflow = isWorkflow?.content
              ? JSON.parse(isWorkflow?.content)
              : {};
            setIsWorkflowFlag(isWorkflow);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting measurement types");
      });
  };

  //functions
  const onManageWorkflow = ({ values, setFieldValue }) => {
    setWorkflowDataSet({
      workflows: values?.workflows,
      setFieldValue,
    });
    setWorkflowModal((prev) => !prev);
  };

  const onStatusModal = (selected) => {
    if (selected) {
      setSelectedSecurityGroupName(selected);
    } else {
      setSelectedSecurityGroupName("");
    }
    setStatusModal(!statusModal);
  };

  return (
    <div className="admin-popular-main">
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}
        message="Feature Service"
        type={successType}
      />

      <div className="header">
        <p className="content-header-main">
          Security Group {pageLoading ? "" : `(${totalAccessRoles || 0})`}
        </p>
        <div className="d-flex gap-3">
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchTraining(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex">
            <Select
              class="form-control form-select custom-status-select"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
              options={selectOptions}
            >
              {selectOptions.map((each) => (
                <option
                  value={each.value}
                  key={each.label}
                  style={{ textTransform: "capitalize" }}
                >
                  {each.label}
                </option>
              ))}
            </Select>
          </div>

          {grantPermission("SecurityGroup_write") && (
            <IconButton
              iconType="add"
              title="Security Group"
              onClick={() => handleEditClick("")}
            />
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : securityGroupName && securityGroupName.length > 0 ? (
        <div className="content">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>Sr.No.</th>
                  <th>Security Group Name</th>
                  <th>Department Name</th>
                  <th>Description</th>
                  <th>Status</th>
                  {/* {grantPermission("AccessRight_update") && <th>Action</th>} */}
                  {/* /  {grantPermission("SecurityGroup_delete") && <th>Action</th>} */}
                  {(grantPermission("SecurityGroup_delete") ||
                    grantPermission("SecurityGroup_update")) && (
                    <th>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {securityGroupName.map((each, index) => {
                  return (
                    <tr key={index}>
                      <td>{each.sNo}</td>
                      <td>{each.securityGroupName}</td>
                      <td>{each?.deptartment?.deptName}</td>
                      <td>{each.description}</td>
                      <td>{each?.status}</td>

                      {/* {grantPermission("AccessRight_update") && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {tools.map((eachTool, toolIndex) => (
                              <div
                                key={toolIndex}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleEditClick(each, eachTool.name);
                                }}
                              >
                                <img src={editicon} alt="Edit icon" />
                                <span>{eachTool.name}</span>
                              </div>
                            ))}
                          </div>
                        </td>
                      )} */}
                      {(grantPermission("SecurityGroup_delete") ||
                        grantPermission("SecurityGroup_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("SecurityGroup_update") &&
                              each?.status !== "inactive" && (
                                <div
                                  onClick={() => onAddSecurityGroup(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}

                            {grantPermission("SecurityGroup_delete") &&
                              each?.status !== "inactive" && (
                                <div
                                  onClick={() => onDeleteSecurityGroup(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}

                            {/* {grantPermission("SecurityGroup_update") &&
                              setting.update_status &&
                              each.status !== setting.approval_status && (
                                <div
                                  onClick={() => onStatusModal(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editgreenicon} alt="edit icon" />
                                </div>
                              )} */}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            {/* <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Created At</th>
                  {grantPermission("AccessRight_update") && <th>Action</th>}
                  {grantPermission("AccessRight_delete") && <th>Delete</th>}
                </tr>
              </thead>
              <tbody>
                {accessRoles &&
                  accessRoles.map((each, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{each?.role}</td>
                        <td>{each?.description}</td>
                        <td>
                          {moment(each?._id?.createdAt).format("DD.MM.YYYY")}
                        </td>
                        {(grantPermission("AccessRight_update") ||
                          grantPermission("AccessRight_delete")) && (
                          <td className="options">
                            <div className="d-flex gap-2">
                              {grantPermission("AccessRight_update") &&
                                tools &&
                                tools.length > 0 &&
                                tools.map((eachTool) => (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleEditClick(each, eachTool.name);
                                    }}
                                  >
                                    <img src={editicon} alt="Edit icon" />
                                    <span>{eachTool.name}</span>
                                  </div>
                                ))}
                            </div>
                          </td>
                        )}
                        {grantPermission("AccessRight_update") && (
                          <td className="options">
                            <div className="d-flex gap-2">
                              {each?._id?.master ? (
                                <></>
                              ) : (
                                grantPermission("AccessRight_delete") && (
                                  <div style={{ cursor: "pointer" }}>
                                    <img
                                      src={removeicon}
                                      alt="Remove icon"
                                      onClick={() => onDeleteAccessRole(each)}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table> */}
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No Security Group Found</div>
      )}
      {accessRoles && accessRoles.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalAccessRoles}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <div className="add-sop-card-flex">
        <Modal
          centered
          show={editAccessRoleModal}
          onHide={() => handleCloseEditModal()}
          className="add-department-modal-main"
          selected={selectedSecurityGroupName}
          size="lg"
        >
          <Modal.Header closeButton className="add-department-modal-header">
            <Modal.Title className="add-department-modal-title">
              Security Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-department-modal-body">
            <Formik
              initialValues={{
                securityGroupName:
                  selectedSecurityGroupName?.securityGroupName || "",
                description: selectedSecurityGroupName?.description || "",
                departmentId: selectedSecurityGroupName?.deptartment || "",
                workflow_name: selectedSecurityGroupName?.name || "",
                workflows: selectedSecurityGroupName?.workflowData || [
                  {
                    workflow: "reviewer",
                    users: [],
                    no_of_approvals: "",
                  },
                  {
                    workflow: "approver",
                    users: [],
                    no_of_approvals: "",
                  },
                ],
                disabled:
                  selectedSecurityGroupName?.disabled === false ||
                  selectedSecurityGroupName?.disabled === "No"
                    ? true
                    : false,
                email: user.email || "",
                password: "",
              }}
              onSubmit={(values) => {
                selectedSecurityGroupName
                  ? updateSecurityGroup(values)
                  : addSecurityGroup(values);
              }}
              validationSchema={
                setting?.workflow_required === 'true'
                  ? updateAccessRoleSchema
                  : accessRoleSchema
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="form" onSubmit={handleSubmit}>
                  <div className="nk-block">
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="form-group ">
                          <label className="form-label" for="securityGroupName">
                            Security Group Name
                            <span className="required-star"> *</span>
                          </label>
                          <div className="form-control-wrap">
                            <PrimaryInput
                              //  onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.securityGroupName}
                              placeholder="Security Group Name"
                              onChange={(e) => {
                                setFieldValue(
                                  "securityGroupName",
                                  e.target.value
                                );
                              }}
                              name="securityGroupName"
                              id="securityGroupName"
                            />
                            <CustomErrorMessage
                              name="securityGroupName"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div>
                          <label className="form-label" for="departmentId">
                            Department
                            <span className="required-star"> *</span>
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              className="sop-custom-form-select"
                              id="departmentId"
                              name="departmentId"
                              placeholder="Select..."
                              onChange={(e) => {
                                setFieldValue("departmentId", e);
                              }}
                              onBlur={handleBlur}
                              value={values.departmentId}
                              options={documentDepartments}
                            />
                            <CustomErrorMessage
                              name="departmentId"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group ">
                          <label className="form-label" for="description">
                            Description
                            <span className="required-star"> *</span>
                          </label>
                          <div className="form-control-wrap">
                            <PrimaryInput
                              onChange={(e) => {
                                setFieldValue("description", e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.description}
                              name="description"
                              id="description"
                              placeholder="Description"
                            />
                            <CustomErrorMessage
                              name="description"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                      </div>

                      {selectedSecurityGroupName
                        ? isWorkflowFlag &&
                          isWorkflowFlag?.standard_workflow === "false" &&
                          setting?.workflow_disabled === "false" &&
                          isWorkflowFlag?.status === "approved"
                        : isWorkflowFlag &&
                          isWorkflowFlag?.standard_workflow === "false" &&
                          setting?.workflow_disabled === "false" && (
                            <>
                              <div
                                className="my-4"
                                style={{ borderBottom: "1px dashed #000" }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-0" style={{ fontWeight: 500 }}>
                                  Manage Workflow :
                                  {setting?.workflow_required === "true" &&
                                    (<span style={{ color: "#f00" }}> *</span>)}
                                </p>
                                <Button
                                  className="theme-btn-main"
                                  style={{ padding: "11px 12px" }}
                                  onClick={() =>
                                    onManageWorkflow({
                                      values,
                                      setFieldValue,
                                    })
                                  }
                                >
                                  Manage Workflow
                                </Button>
                              </div>
                              <div className="workflows-list">
                                {values.workflows &&
                                  values.workflows.length > 0 &&
                                  values.workflows.map((each_work, index) => {
                                    // Generate the options array for the number of approvals
                                    const options = Array.from(
                                      {
                                        length:
                                          each_work?.users &&
                                          each_work?.users.length > 0 &&
                                          each_work?.users.length,
                                      },
                                      (_, i) => ({
                                        label: `${i + 1}`,
                                        value: i + 1,
                                      })
                                    );

                                    return (
                                      <div
                                        className="each-work-flow mt-3"
                                        key={index}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <div className="workflow-head">
                                            <span
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {each_work.workflow}
                                            </span>
                                          </div>
                                          <div className="form-group mb-1 d-flex gap-2 align-items-center">
                                            <label
                                              className="form-label mb-0"
                                              htmlFor="category"
                                            >
                                              {`Select Number of ${each_work.workflow}`}
                                              <span style={{ color: "#f00" }}>
                                                {" "}
                                                *
                                              </span>
                                            </label>
                                            <div className="form-control-wrap">
                                              <select
                                                className="sop-custom-form-select"
                                                id={`workflows.${index}.no_of_approvals`}
                                                name={`workflows.${index}.no_of_approvals`}
                                                placeholder="Select"
                                                onChange={(option) => {
                                                  setFieldValue(
                                                    `workflows.${index}.no_of_approvals`,
                                                    option?.target.value
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                style={{
                                                  width: "100px",
                                                  fontSize: "12px",
                                                  padding: "6px",
                                                }}
                                              >
                                                <option>Select...</option>
                                                {options &&
                                                  options?.length > 0 &&
                                                  options.map((option) => {
                                                    return (
                                                      <option
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    );
                                                  })}
                                              </select>
                                              <CustomErrorMessage
                                                name={`noOfApprovals_${index}`}
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="users mt-2 px-3">
                                          {each_work.users &&
                                            each_work.users.length > 0 &&
                                            each_work.users.map(
                                              (user, subI) => (
                                                <div
                                                  className="each-user d-flex justify-content-between mt-2"
                                                  key={subI}
                                                >
                                                  <div>
                                                    <span>{subI + 1}.</span>
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {user.label}
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <img
                                                      alt="Cross"
                                                      src={Cross}
                                                      className="delete-topic"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        let data =
                                                          values?.workflows ||
                                                          [];
                                                        let users =
                                                          data[index].users ||
                                                          [];
                                                        users = users.filter(
                                                          (each, i) =>
                                                            subI !== i
                                                        );
                                                        data[index].users =
                                                          users;
                                                        setFieldValue(
                                                          "workflows",
                                                          data
                                                        );
                                                        setFieldValue(
                                                          `workflows.${index}.no_of_approvals`,
                                                          ""
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              {/* {values.workflows &&
                                values.workflows.length > 0 &&
                                values.workflows[0]?.users &&
                                values.workflows[0]?.users.length === 0 && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      backgroundColor: "white",
                                      padding: "5px",
                                    }}
                                  >
                                    Will Appear Here
                                  </div>
                                )} */}
                            </>
                          )}

                      {setting.digital_signature && (
                        <>
                          <div
                            className="my-4"
                            style={{ borderBottom: "1px dashed #000" }}
                          />
                          <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                            Digital Signature :
                          </p>
                          <div className="form-group mt-3 col-12 ">
                            <label className="mx-3 mb-1">Login Id</label>
                            <div className="w-100 px-3">
                              <PrimaryInput
                                placeholder="Enter Login Id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                name="email"
                                type="text"
                                disabled={true}
                              />
                              <CustomErrorMessage
                                errors={errors}
                                touched={touched}
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="form-group mt-3 col-12 ">
                            <label className="mx-3 mb-1">Password</label>

                            <div className="w-100 px-3">
                              <PrimaryInput
                                placeholder="Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                name="password"
                                type="password"
                              />
                              <CustomErrorMessage
                                errors={errors}
                                touched={touched}
                                name="password"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-12 mt-3">
                        <div className="d-flex gap-2 justify-content-end">
                          <Button
                            className="back-icon-btn-main"
                            onClick={onAddSecurityGroup}
                          >
                            <span>{squareBackIcon}</span>
                            <span className="ms-2">Back</span>
                          </Button>
                          <PrimaryButton
                            className="theme-btn-main"
                            type="submit"
                            title={
                              updateLoading ? (
                                <ButtonLoading />
                              ) : selectedSecurityGroupName ? (
                                "Submit"
                              ) : (
                                "Submit"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
      <Modal
        centered
        show={deleteAccessRoleModal}
        onHide={onDeleteSecurityGroup}
        className="add-department-modal-main role-delete-model"
        selected={selectedSecurityGroupName}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Role
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <p>
            Do you want to delete this Role{" "}
            <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
              {selectedSecurityGroupName?.securityGroupName}
            </span>
            ?
          </p>

          <Formik
            initialValues={{
              email: user.email,
              password: "",
            }}
            validationSchema={setting.digital_signature && deleteSchema}
            onSubmit={(values) =>
              onSecurityGroupStatusChange({
                status: "inactive",
                password: values?.password,
              })
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                {setting.digital_signature && (
                  <>
                    <div
                      className="my-4"
                      style={{ borderBottom: "1px dashed #000" }}
                    />
                    <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                      Digital Signature :
                    </p>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Login Id</label>
                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Enter Login Id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                          type="text"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-12 ">
                      <label className="mx-3 mb-1">Password</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          type="password"
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="password"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button
                      className="back-icon-btn-main"
                      onClick={onDeleteSecurityGroup}
                    >
                      <span>{squareBackIcon}</span>
                      <span className="ms-2">Back</span>
                    </Button>
                    <PrimaryButton
                      className="theme-btn-main"
                      type="submit"
                      title={buttonLoading ? <ButtonLoading /> : "Delete"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={statusModal}
        onHide={onStatusModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Status
          </Modal.Title>
        </Modal.Header>

        {statusModal && (
          <Modal.Body className="add-department-modal-body">
            <StatusModalContent
              setting={setting}
              statusApiFunction={onSecurityGroupStatusChange}
              onClose={onStatusModal}
              loading={buttonLoading}
            />
          </Modal.Body>
        )}
      </Modal>

      <Modal
        centered
        show={workflowModal}
        onHide={() => onManageWorkflow({})}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Manage Workflow
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <IsWorkflowSlug
            onManageWorkflow={() => onManageWorkflow({})}
            workflowDataSet={workflowDataSet}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SecurityGroup;
