import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./AuthLayout.scss";
import useTheme from "../../hooks/useTheme";

export default function AuthLayout(props) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const { theme } = useTheme();
  return (
    <div className={`layout-main ${theme}`}>
      <div
        className={
          sidebarToggle
            ? "sidebar-content"
            : "sidebar-content sidebar-content-hidden"
        }
      >
        <Sidebar setSidebarToggle={setSidebarToggle} />
      </div>
      <div
        className="header-and-content"
        onClick={() => {
          if (sidebarToggle) {
            setSidebarToggle(false);
          }
        }}
      >
        <Header Header setSidebarToggle={setSidebarToggle} />
        <div className="content-main">{props.children}</div>
      </div>
    </div>
  );
}
