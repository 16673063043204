import { useState, useEffect } from "react";
import nexticon from "../images/icons/next-arrow.svg";
import "./CustomPagination.scss";
import backIcon from "../images/icons/previous.svg";
import nextIcon from "../images/icons/arrow-right-grey.svg";

const Pagination = ({
  currentPage,
  rowsPerPage,
  totalCount,
  onPageClick,
  onPrev,
  onNext,
}) => {
  const [pageList, setPageList] = useState([]);
  let page = +currentPage;
  let limit = Math.ceil(totalCount / rowsPerPage);

  useEffect(() => {
    const generatePageList = () => {
      let pages = [];
      if (limit <= 5) {
        for (let i = 1; i <= limit; i++) {
          pages.push(i);
        }
      } else {
        if (page <= 3) {
          pages = [1, 2, 3, 4, 5];
        } else if (page > limit - 3) {
          pages = [limit - 4, limit - 3, limit - 2, limit - 1, limit];
        } else {
          pages = [page - 2, page - 1, page, page + 1, page + 2];
        }
      }
      setPageList(pages);
    };
    generatePageList();
  }, [page, limit]);

  const onNextPage = () => {
    if (page < limit) {
      onNext && onNext(page + 1);
    }
  };

  const onPreviousPage = () => {
    if (page > 1) {
      onPrev && onPrev(page - 1);
    }
  };

  const onPage = (number) => {
    onPageClick && onPageClick(number);
  };

  return (
    <div className="nk-block-between-md g-3">
      <div className="g">
        <ul className="pagination justify-content-center justify-content-md-start">
          <li
            className={`page-item ${page === 1 ? "disabled" : ""}`}
            onClick={onPreviousPage}
          >
            <span className="page-link">
              <img style={{ width: "100%" }} src={backIcon} alt="back icon" />
            </span>
          </li>
          {pageList.map((pageNum) => (
            <li
              key={pageNum}
              className={`page-item ${page === pageNum ? "active" : ""}`}
              onClick={() => onPage(pageNum)}
            >
              <span className="page-link">{pageNum}</span>
            </li>
          ))}
          <li
            className={`page-item ${page === limit ? "disabled" : ""}`}
            onClick={onNextPage}
          >
            <span className="page-link">
              <img style={{ width: "100%" }} src={nextIcon} alt="next icon" />
            </span>
          </li>
        </ul>
      </div>
      <div className="g">
        <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
          <div
            className="d-flex gap-3 px-2 py-3 justify-content-between align-items-center border rounded right-next"
            onClick={onNextPage}
          >
            <p className="m-0">Next</p>
            <img src={nexticon} alt="next" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
