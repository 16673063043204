import React from "react";
import "./HeadingMain.scss";

const HeadingMain = (props) => {
  return (
    <div className="heading">
      <h3 className="heading-main">{props.text}</h3>
      {props.children}
    </div>
  );
};

export default HeadingMain;
