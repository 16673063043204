import React from "react";
import "./IconButton.scss";
import { Button } from "reactstrap";
import plusicon from "../../assets/images/icons/add.svg";
import useTheme from "../../hooks/useTheme";

export default function IconButton(props) {
  const { theme } = useTheme();
  return (
    <Button
      {...props}
      className={`btn-with-icon ${theme} ${
        props.className || props.class ? props.class || props.className : ""
      }`}
    >
      <span>{props.icon}</span>
      {props.iconType === "add" ? <img src={plusicon} alt="add" /> : ""}
      <span className="btn-with-icon-title">{props.title}</span>
    </Button>
  );
}
