import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";

export const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (params) =>
      navigate({ search: `?${createSearchParams(params)}` });
  };

export const useLocationParams = () => {
    const [searchParams] = useSearchParams();
    const searchParamsObject = Object.fromEntries([...searchParams]);
    return searchParamsObject;
}