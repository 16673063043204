import React, { useEffect, useState } from "react";
import MainServices from "../../../services/MainServices";
import PageLoader from "../../../assets/loaders/PageLoading";
import "./AddIDCMatrix.scss";
import { CustomInput } from "react-bootstrap";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import ArrowBack from "../../../assets/images/icons/arrow-left.svg";
import Cross from "../../../assets/images/icons/cross.svg";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import ItemsDroppable from "../../../assets/dragDrop/ItemsDroppable";
import EachDragItem from "../../../assets/dragDrop/EachDragItem";
import AsyncSelect from "react-select/async";
import { Modal, Form, InputGroup } from "react-bootstrap";
import * as Yup from "yup";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../assets/dnd/StrictModeDroppable";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import useFeatureSetting from "../../../hooks/useFeatureSetting";
import useAuth from "../../../hooks/useAuth";

export default function AddIDCMatrix() {
  const [filters, setFilters] = useState({});
  const { deBounce } = TimingFunctions();
  const { getEmployeeMasters, getDepartmentMasters, getDesignationMasters } =
    MainServices();
  const [pageLoading, setPageLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectionList, setSelectionList] = useState([]);
  const [selectionLabel, setSelectionLabel] = useState("");
  const [workflowTypeLabel, setWorkflowTypeLabel] = useState("");
  const [selectedDepartmentDesignation, setSelectedDepartmentDesignation] =
    useState("");
  const [workflowType, setWorkflowType] = useState({});
  const [departmentName, setDepartmentName] = useState({});
  const [designationName, setDesignationName] = useState({});
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [workflowModal, setWorkflowModal] = useState(false);
  const { AddIDCMasters, getIDCMasters, updateIDCMaster } = MainServices();
  const [selectedIDCMatrix, setSelectedIDCMatrix] = useState();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [selectedPosition, setSelectedPosition] = useState(0);
  const { updateSettingContext, setting } = useFeatureSetting();
  const { user } = useAuth();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    if (publicId) {
      getIDCMastersById();
    }
  }, [publicId]);

  useEffect(() => {
    updateSettingContext("workflow");
  }, []);

  const addIDCSchema = Yup.object().shape({
    workflows: Yup.mixed().required("WorkFlows is Required"),
  });

  const updateIDCSchema = Yup.object().shape({
    workflows: Yup.mixed().required("WorkFlows is Required"),
    password: Yup.string().required("Password is Required"),
  });

  useEffect(() => {
    console.log(changedParams);
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);
    let pagination = {
      limit: changedParams?.limit || 20,
      page: changedParams?.page || 1,
    };
    getEmployeesByDiscipline({ value: "all", filters });
    getDepartmentByDiscipline({ value: "all", filters });
    getDesignationByDiscipline({ value: "all", filters });
  }, [changedParams.filters, searchString]);

  const onManageWorkflow = () => {
    setWorkflowModal((prev) => !prev);
  };

  //apis
  const getIDCMastersById = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: publicId }],
    };
    await axios
      .all([getIDCMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            setSelectedIDCMatrix(resData.data[0]);
            onManageWorkflow();
            setPageLoading(false);
            if (resData.data?.[0]?.workflowData?.[0]?.workflowType) {
              const _workflow = resData.data[0]?.workflowData[0]?.workflowType;
              if (_workflow === "department_designation") {
                setWorkflowTypeLabel("department_designation");
                setSelectionLabel("Department / Designation");
                setDepartmentName({
                  label: resData.data[0]?.workflowData[0]?.users[0]?.label,
                  value: resData.data[0]?.workflowData[0]?.users[0]?.value,
                });
                setDesignationName({
                  label: resData.data[0]?.workflowData[0]?.users[1]?.label,
                  value: resData.data[0]?.workflowData[0]?.users[1]?.value,
                });
              } else {
                setWorkflowTypeLabel("users");
                setSelectionLabel("Users");
              }
              // setWorkflowType({
              //   ...workflowType,
              //   workflow: _workflow,
              //   [_workflow]: {
              //     label: "Department / Designation",
              //     value: "department_designation",
              //   },
              // });
            }
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting IDC Matrix");
      });
  };

  const getEmployeesByDiscipline = async ({ filters, page, limit }) => {
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };
    getEmployeeMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.empName,
            value: each.publicId,
          }));

          setEmployees(resData);
          setSelectionList(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // getting Department details with pagination
    getDepartmentMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.deptName,
            value: each.deptCode,
          }));

          setDepartments(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // getting Designation details with pagination
    getDesignationMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.desgName,
            value: each.desgCode,
          }));

          setDesignations(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentByDiscipline = async ({ filters, page, limit }) => {
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };

    // getting Department details with pagination
    getDepartmentMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.deptName,
            value: each.deptCode,
          }));

          setDepartments(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDesignationByDiscipline = async ({ filters, page, limit }) => {
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };
    // getting Designation details with pagination
    getDesignationMasters(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.desgName,
            value: each.desgCode,
          }));

          setDesignations(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateIDC = async (values) => {
    setButtonLoading(true);
    console.log(values);
    const details = {
      workflowData: values.workflows,
      disabled: values.disabled === true ? "No" : "Yes",
      ...(values?.password && { password: values?.password }),
    };
    await axios
      .all([
        updateIDCMaster(
          details,
          selectedIDCMatrix?.publicId,
          selectedIDCMatrix?.concurrencyStamp
        ),
      ])
      .then((res) => {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("WorkFlow Updated Successfully");
            navigate(-1);
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Workflow Master");
      });
  };

  //functions
  const onDragItem = ({ item, setFieldValue, workflow, values }) => {
    let workflows = values.workflows || [];
    workflows = workflows.map((eachW) => {
      if (eachW.workflow === workflow) {
        let users = eachW.users || [];
        let match = users.find((each) => each.value === item.value);

        if (!eachW?.users) {
          eachW["users"] = users;
        }

        if (!match) {
          users.push(item);
        }
        return {
          ...eachW,
          users,
        };
      }
      return eachW;
    });
    setFieldValue(workflows);
  };

  const onSearchdDeptDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdDept(inputValue, callback), 1000);
  };

  const onSearchdDept = async (inputValue, callback) => {
    if (!inputValue) {
      callback(departments);
      return;
    }

    let filters = [
      {
        key: "deptName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    const details = {
      filters,
      // status: "active",
    };

    let options = await getDepartmentMasters(details).then((res) => {
      if (res) {
        let resData = res;
        if (resData.success) {
          let departments = resData?.data;
          departments = departments.map((each) => {
            return {
              ...each,
              label: each?.deptName,
              value: each?.publicId,
            };
          });
          departments.unshift({ label: "--All--", value: "" });
          !true && setDepartments(departments);
          return departments;
        } else {
        }
      } else {
      }
    });
    callback(options);
  };

  const onSearchdDesigDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdDesig(inputValue, callback), 1000);
  };

  const onSearchdDesig = async (inputValue, callback) => {
    if (!inputValue) {
      callback(designations);
      return;
    }

    let filters = [
      {
        key: "desgName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    const details = {
      filters,
      // status: "active",
    };
    let options = await getDesignationMasters(details).then(function (res) {
      if (res[0]) {
        let resData = res[0];
        let message = resData.message;
        if (resData.success) {
          let designations = resData?.data || [];
          designations = designations.map((each) => {
            return {
              ...each,
              label: each?.desgName,
              value: each?.publicId,
            };
          });
          !true && setDesignations(designations);
          return designations;
        } else {
          if (message) notifyWarn(message);
        }
      } else {
      }
    });
    callback(options);
  };

  // const handleSelectChange = (value, key, workflows, setFieldValue) => {
  //   if(key == 'dept') setDepartmentName(value);
  //   else setDesignationName(value);

  //   const { value:w_value } = value;

  //   workflows = workflows.map((eachW) => {
  //     if (eachW.workflow === workflowType?.workflow) {
  //       let users = eachW.users || [];
  //       let match = users.find((each) => each.value === w_value);

  //       if(!eachW?.users) {
  //         eachW['users'] = users;
  //       }

  //       const checkDeptNDesig = (_users) => {
  //         if(_users?.length > 0) {
  //           const existingDept = users.find((each) => each?._dkey == 'dept');
  //           const existingDesig = users.find((each) => each?._dkey == 'desig');

  //           if(!existingDept && !existingDesig) return true;
  //           else if(!existingDept && existingDesig && key == 'dept') return true;
  //           else if(existingDept && !existingDesig && key == 'desig') return true;
  //           else return false;
  //         }

  //         return true
  //       }

  //       if (!match && checkDeptNDesig(users)) {
  //         users.push({ ...value, _dkey: key });
  //       }

  //       return {
  //         ...eachW,
  //         users,
  //       };
  //     }
  //     return eachW;
  //   });

  //   setFieldValue(workflows);
  // }

  const searchemployee = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      empName: { empName: e.target.value },
    });
    let search = filtersToUrlParams({
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const onDragEnd = (result, set, values) => {
    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) {
      return;
    }

    // If the drag type is 'SECTION'

    const update_values = reorder(
      values.workflows,
      source.index,
      destination.index
    );
    set("workflows", update_values);

    // If the drag type is 'ITEM' within the same section
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // const handleChangeEvent = (value, _section, _workflow, workflows, setFieldValue) => {
  //   const { workflow } = _section;
  //   const { value:w_value } = value;

  //   workflows = workflows.map((eachW) => {
  //     if (eachW.workflow === workflow) {
  //       let users = [];

  //       if(!eachW?.users) {
  //         eachW['users'] = users;
  //       }

  //       return {
  //         ...eachW,
  //         users,
  //       };
  //     }
  //     return eachW;
  //   });

  //   setFieldValue({ ..._workflow, "workflows": workflows, "workflow_type": w_value });
  //   setWorkflowType({ ...workflowType, 'workflow': workflow, [workflow]: value });
  //   setWorkflowTypeLabel(w_value);

  //   if(w_value?.toLowerCase() != "users") {
  //     setSelectionLabel("Department / Designation");
  //     const _selection_list = [...departments, ...designations];

  //     setSelectionList(_selection_list);
  //   } else {
  //     setSelectionLabel("Users");

  //     setSelectionList(employees);
  //   }
  // }

  return (
    <div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="addidcmatrix-main">
          <Formik
            initialValues={{
              workflow_name: selectedIDCMatrix?.name || "",
              workflows: selectedIDCMatrix?.workflowData || [
                {
                  workflow: "reviewer",
                  users: [],
                  noOfApprovals: "",
                },
                {
                  workflow: "approver",
                  users: [],
                  noOfApprovals: "",
                },
              ],
              disabled:
                selectedIDCMatrix?.disabled === false ||
                selectedIDCMatrix?.disabled === "No"
                  ? true
                  : false,
              email: user?.email || "",
              password: "",
            }}
            onSubmit={(values) => {
              selectedIDCMatrix && updateIDC(values);
            }}
            validationSchema={
              setting.digital_signature ? updateIDCSchema : addIDCSchema
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <div className="workflow-manager">
                  {console.log(values)}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="back-button mb-5">
                      <img
                        src={ArrowBack}
                        alt=""
                        onClick={() => navigate(-1)}
                      />
                    </div>
                    {console.log(errors)}
                    {/* <CustomInput
                      type="switch"
                      id="disabled"
                      name="disabled"
                      checked={values?.disabled}
                      onChange={(e) =>
                        setFieldValue("disabled", e.target.checked)
                      }
                    /> */}

                    <label className="switch">
                      <input
                        type="checkbox"
                        id="disabled"
                        name="disabled"
                        checked={values?.disabled}
                        onChange={(e) => {
                          setFieldValue("disabled", e.target.checked);
                          // updateIDC(values);
                        }}
                      />

                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      className="p-0 content-header-main"
                      style={{ fontWeight: "700", fontSize: "24px" }}
                    >
                      ADD WorkFlow
                    </p>
                    <Button
                      onClick={onManageWorkflow}
                      className="theme-btn-main"
                    >
                      Manage WorkFlow
                    </Button>
                  </div>
                </div>
                <hr />

                <div className="workflows-list">
                  {values.workflows &&
                    values.workflows.length > 0 &&
                    values.workflows.map((each_work, index) => {
                      // Generate the options array for the number of approvals
                      let options = [];
                      if (each_work?.workflowType !== "users") {
                        options = Array.from(
                          {
                            length: 10,
                          },
                          (_, i) => ({ label: `${i + 1}`, value: i + 1 })
                        );
                      } else {
                        options = Array.from(
                          {
                            length:
                              each_work?.users &&
                              each_work?.users.length > 0 &&
                              each_work?.users.length,
                          },
                          (_, i) => ({ label: `${i + 1}`, value: i + 1 })
                        );
                      }

                      return (
                        <div className="each-work-flow mt-3" key={index}>
                          <div className="d-flex justify-content-between">
                            <div className="workflow-head">
                              <span style={{ textTransform: "capitalize" }}>
                                {each_work.workflow} -{" "}
                                {each_work.workflowType === "users"
                                  ? "Users"
                                  : "Department / Designation"}
                              </span>
                            </div>
                            <div className="form-group mb-1 d-flex gap-2 align-items-center">
                              <label
                                className="form-label mb-0"
                                htmlFor="category"
                              >
                                {`Select Number of ${each_work.workflow}`}
                                <span style={{ color: "#f00" }}> *</span>
                              </label>
                              <div className="form-control-wrap">
                                <Select
                                  options={options}
                                  className="sop-custom-form-select"
                                  id={`noOfApprovals_${index}`}
                                  name={`noOfApprovals_${index}`}
                                  placeholder="Select"
                                  onChange={(option) => {
                                    const selectedLabel = option
                                      ? option.label
                                      : "";
                                    const updatedWorkflows = [
                                      ...values.workflows,
                                    ];
                                    updatedWorkflows[index] = {
                                      ...updatedWorkflows[index],
                                      noOfApprovals: selectedLabel,
                                    };
                                    setFieldValue(
                                      "workflows",
                                      updatedWorkflows
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={options.find(
                                    (option) =>
                                      option.label === each_work.noOfApprovals
                                  )}
                                />
                                <CustomErrorMessage
                                  name={`noOfApprovals_${index}`}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="users mt-2 px-3">
                            {each_work.users &&
                              each_work.users.length > 0 &&
                              each_work.users.map((user, subI) => (
                                <div
                                  className="each-user d-flex justify-content-between mt-2"
                                  key={subI}
                                >
                                  <div>
                                    <span>{subI + 1}.</span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {user.label}
                                    </span>
                                  </div>
                                  <div>
                                    <img
                                      alt="Cross"
                                      src={Cross}
                                      className="delete-topic"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let data = values?.workflows || [];
                                        let users = data[index].users || [];
                                        users = users.filter(
                                          (each, i) => subI !== i
                                        );
                                        data[index].users = users;
                                        setFieldValue("workflows", data);
                                      }}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {values.workflows &&
                  values.workflows.length > 0 &&
                  values.workflows[0]?.users &&
                  values.workflows[0]?.users.length === 0 && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: "200px",
                        height: "200px",
                        backgroundColor: "white",
                        padding: "5px",
                      }}
                    >
                      Will Appear Here
                    </div>
                  )}

                <hr />

                {setting.digital_signature && selectedIDCMatrix && (
                  <div className="row mb-5">
                    <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
                      Digital Signature :
                    </p>
                    <div className="form-group mt-3 col-6">
                      <label className="mx-3 mb-1">Login Id</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Enter Login Id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          name="email"
                          type="text"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3 col-6">
                      <label className="mx-3 mb-1">Password</label>

                      <div className="w-100 px-3">
                        <PrimaryInput
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          name="password"
                          type="password"
                        />
                        <CustomErrorMessage
                          errors={errors}
                          touched={touched}
                          name="password"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-end gap-3 mt-3">
                  <button
                    className="col-2 btn btn-primary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary col-2"
                    onClick={handleSubmit}
                  >
                    {buttonLoading ? (
                      <ButtonLoading />
                    ) : selectedIDCMatrix ? (
                      "Submit WorkFlow"
                    ) : (
                      "Submit WorkFlow "
                    )}
                  </button>
                </div>

                <Modal
                  centered
                  show={workflowModal}
                  onHide={() => onManageWorkflow()}
                  className="add-department-modal-main"
                  size="xl"
                >
                  <Modal.Header
                    closeButton
                    className="add-department-modal-header"
                  >
                    <Modal.Title className="add-department-modal-title">
                      Manage Workflow
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="add-department-modal-body">
                    <div className="admin-popular-main">
                      <div className="d-flex justify-content-end">
                        <InputGroup
                          style={{
                            width: "auto",
                            border: "0.5px solid #000",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control
                            placeholder="Search User"
                            aria-label="Search"
                            aria-describedby="search"
                            className="search-input-main"
                            onChange={(e) => searchemployee(e)}
                          />
                          <InputGroup.Text
                            id="search"
                            className="search-input-main"
                          >
                            <img src={searchicon} alt="search icon" />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        workflows: values.workflows,
                        workflow: "",
                      }}
                      onSubmit={(m_values) => {
                        setFieldValue("workflows", m_values.workflows);
                        onManageWorkflow();
                      }}
                      key=""
                    >
                      {({
                        handleSubmit: m_submit,
                        setFieldValue: m_set,
                        values: m_values,
                        handleChange: m_change,
                        handleBlur: m_blur,
                      }) => (
                        <>
                          <div>
                            <div className="form-group my-2">
                              <label
                                className="form-label"
                                for="passing_percentage"
                              >
                                Workflow Name
                              </label>
                              <div className="form-control-wrap">
                                <div className="d-flex gap-2 ">
                                  <PrimaryInput
                                    type="text"
                                    className="form-control"
                                    id="workflow"
                                    name="workflow"
                                    onChange={m_change}
                                    onBlur={m_blur}
                                    value={m_values.workflow}
                                    placeholder="Enter"
                                  />
                                  <Button
                                    onClick={() => {
                                      let workflows = m_values.workflows || [];
                                      let data = m_values.workflows || [];
                                      if (m_values.workflow) {
                                        let isthere = false;
                                        data.forEach((each) => {
                                          if (
                                            m_values.workflow.toLowerCase() ===
                                            each.workflow.toLowerCase()
                                          ) {
                                            isthere = true;
                                          }
                                        });
                                        if (!isthere) {
                                          workflows.push({
                                            workflow:
                                              m_values.workflow.toLowerCase(),
                                            users: [],
                                          });
                                        }
                                      }
                                      m_set("workflows", workflows);
                                    }}
                                  >
                                    Add
                                  </Button>
                                </div>
                                <CustomErrorMessage
                                  name="passing_percentage"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex gap-2"
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{
                                width: "49%",
                                height: 500,
                                overflowY: "scroll",
                              }}
                            >
                              <h6 className="mt-3">WorkFlows</h6>
                              <div className="d-flex gap-2 mb-3">
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    onDragEnd(result, m_set, m_values)
                                  }
                                >
                                  <StrictModeDroppable
                                    droppableId="workflows"
                                    direction="vertical"
                                  >
                                    {(sectionsProvided) => (
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                        {...sectionsProvided.droppableProps}
                                        ref={sectionsProvided.innerRef}
                                      >
                                        {console.log(m_values)}
                                        {m_values.workflows.map(
                                          (section, sectionIndex) => (
                                            <Draggable
                                              key={section.workflow}
                                              draggableId={section.workflow}
                                              index={sectionIndex}
                                              style={{ width: "100%" }}
                                            >
                                              {(sectionProvided) => (
                                                <div
                                                  {...sectionProvided.draggableProps}
                                                  {...sectionProvided.dragHandleProps}
                                                  ref={sectionProvided.innerRef}
                                                >
                                                  <ItemsDroppable
                                                    onDrag={(props) =>
                                                      onDragItem({
                                                        ...props,
                                                        workflow:
                                                          section.workflow,
                                                        values: m_values,
                                                      })
                                                    }
                                                    style={{ height: 150 }}
                                                    setFieldValue={m_set}
                                                    dataType="Available"
                                                  >
                                                    {console.log(m_values)}
                                                    <div className="each-section my-3">
                                                      {console.log(
                                                        "values",
                                                        values
                                                      )}
                                                      <div className="d-flex justify-content-between">
                                                        <h6
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                          role="button"
                                                          onClick={(e) => {
                                                            setSelectedPosition(
                                                              sectionIndex
                                                            );
                                                            setWorkflowTypeLabel(
                                                              section?.workflowType
                                                            );

                                                            if (
                                                              section?.workflowType ===
                                                              "users"
                                                            ) {
                                                              setSelectionLabel(
                                                                "Users"
                                                              );
                                                            } else {
                                                              if (
                                                                section?.users
                                                                  .length
                                                              ) {
                                                                section.users.map(
                                                                  (_) => {
                                                                    if (
                                                                      _?.deptName
                                                                    ) {
                                                                      setDepartmentName(
                                                                        {
                                                                          label:
                                                                            _?.deptName,
                                                                          value:
                                                                            _?.publicId,
                                                                        }
                                                                      );
                                                                    } else {
                                                                      setDesignationName(
                                                                        {
                                                                          label:
                                                                            _?.desgName,
                                                                          value:
                                                                            _?.publicId,
                                                                        }
                                                                      );
                                                                    }
                                                                    return _;
                                                                  }
                                                                );
                                                              }
                                                              setSelectionLabel(
                                                                "Department / Designation"
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {sectionIndex + 1}.
                                                          {section.workflow}
                                                        </h6>
                                                        <div className="form-control-wrap">
                                                          {console.log(
                                                            m_values
                                                          )}
                                                          {console.log(
                                                            workflowTypeLabel,
                                                            "gfdsefrgth"
                                                          )}
                                                          <AsyncSelect
                                                            style={{
                                                              width: 200,
                                                            }}
                                                            className="sop-custom-form-select"
                                                            id="departmentId"
                                                            name="departmentId"
                                                            placeholder="Select..."
                                                            onChange={(
                                                              value,
                                                              event
                                                            ) => {
                                                              const {
                                                                value: w_value,
                                                              } = value;
                                                              let data =
                                                                m_values.workflows ||
                                                                [];
                                                              {
                                                                console.log(
                                                                  w_value
                                                                );
                                                              }
                                                              data = data.map(
                                                                (eachW) => {
                                                                  if (
                                                                    eachW.workflow ===
                                                                    section.workflow
                                                                  ) {
                                                                    let users =
                                                                      [];

                                                                    if (
                                                                      !eachW?.users
                                                                    ) {
                                                                      eachW[
                                                                        "users"
                                                                      ] = users;
                                                                    }

                                                                    return {
                                                                      ...eachW,
                                                                      users,
                                                                      workflowType:
                                                                        w_value,
                                                                    };
                                                                  }
                                                                  return eachW;
                                                                }
                                                              );
                                                              m_set(
                                                                "workflows",
                                                                data
                                                              );
                                                              m_set(
                                                                "workflow_type",
                                                                w_value
                                                              );
                                                              setWorkflowTypeLabel(
                                                                w_value
                                                              );
                                                              if (
                                                                value.value ===
                                                                "users"
                                                              ) {
                                                                setSelectionLabel(
                                                                  "Users"
                                                                );
                                                              } else {
                                                                setSelectionLabel(
                                                                  "Department / Designation"
                                                                );
                                                              }
                                                              event.stopPropagation();

                                                              // handleChangeEvent(value, section, m_values, m_values.workflows, setFieldValue)
                                                            }}
                                                            value={{
                                                              label:
                                                                section?.workflowType ===
                                                                "department_designation"
                                                                  ? "Department / Designation"
                                                                  : "Users",
                                                              value:
                                                                section?.workflowType ===
                                                                "department_designation"
                                                                  ? "department_designation"
                                                                  : "users",
                                                            }}
                                                            options={[
                                                              {
                                                                label: "Users",
                                                                value: "users",
                                                              },
                                                              {
                                                                label:
                                                                  "Department / Designation",
                                                                value:
                                                                  "department_designation",
                                                              },
                                                            ]}
                                                            defaultOptions={[
                                                              {
                                                                label: "Users",
                                                                value: "users",
                                                              },
                                                              {
                                                                label:
                                                                  "Department / Designation",
                                                                value:
                                                                  "department_designation",
                                                              },
                                                            ]}
                                                          />
                                                          <CustomErrorMessage
                                                            name="departmentId"
                                                            errors={errors}
                                                            touched={touched}
                                                          />
                                                        </div>
                                                        <div>
                                                          <img
                                                            alt="Cross"
                                                            src={Cross}
                                                            className="delete-topic"
                                                            onClick={() => {
                                                              let data =
                                                                m_values.workflows ||
                                                                [];
                                                              data =
                                                                data.filter(
                                                                  (each, i) =>
                                                                    i !==
                                                                    sectionIndex
                                                                );
                                                              m_set(
                                                                "workflows",
                                                                data
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="users mt-2 px-3">
                                                        {section.users &&
                                                          section.users.length >
                                                            0 &&
                                                          section.users.map(
                                                            (user, subI) => (
                                                              <div className="each-user d-flex justify-content-between mt-2">
                                                                <div
                                                                // onClick={() => {
                                                                //   if (
                                                                //     workflowTypeLabel !==
                                                                //     "users"
                                                                //   ) {
                                                                //     setSelectedDepartmentDesignation(
                                                                //       {
                                                                //         dept: {
                                                                //           label:
                                                                //             section?.workflow,
                                                                //           value:
                                                                //             section?.departmentId,
                                                                //         },
                                                                //         desg: {
                                                                //           label:
                                                                //             user?.workflow,
                                                                //           value:
                                                                //             user?.departmentId,
                                                                //         },
                                                                //       }
                                                                //     );
                                                                //   }
                                                                // }}
                                                                >
                                                                  <span>
                                                                    {subI + 1}.
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                  >
                                                                    {user.label}
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <img
                                                                    alt="Cross"
                                                                    src={Cross}
                                                                    className="delete-topic"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      let data =
                                                                        m_values?.workflows ||
                                                                        [];
                                                                      let users =
                                                                        data[
                                                                          sectionIndex
                                                                        ]
                                                                          .users ||
                                                                        [];
                                                                      users =
                                                                        users.filter(
                                                                          (
                                                                            each,
                                                                            i
                                                                          ) =>
                                                                            subI !==
                                                                            i
                                                                        );
                                                                      data[
                                                                        sectionIndex
                                                                      ].users =
                                                                        users;
                                                                      m_set(
                                                                        "workflows",
                                                                        data
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  </ItemsDroppable>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {sectionsProvided.placeholder}
                                      </div>
                                    )}
                                  </StrictModeDroppable>
                                </DragDropContext>
                              </div>
                            </div>

                            <div className="available" style={{ width: "49%" }}>
                              {workflowTypeLabel != "users" ? (
                                <>
                                  <h5>{selectionLabel}</h5>
                                  <div>
                                    <label
                                      className="form-label"
                                      for="departmentId"
                                    >
                                      Department
                                      <span className="required-star"> *</span>
                                    </label>
                                    <div className="form-control-wrap">
                                      <AsyncSelect
                                        className="sop-custom-form-select"
                                        id="departmentId"
                                        name="departmentId"
                                        placeholder="Select..."
                                        onChange={(value) => {
                                          const key = "dept";
                                          setDepartmentName(value);
                                          let data = m_values.workflows || [];
                                          data = data.map((eachW, index) => {
                                            if (index === selectedPosition) {
                                              let users = eachW?.users || [];
                                              if (!users.length) {
                                                return {
                                                  ...eachW,
                                                  users: [value],
                                                };
                                              } else {
                                                let departmentExist = false;
                                                users = users.map((eachu) => {
                                                  if (eachu?.deptName) {
                                                    departmentExist = true;
                                                    return value;
                                                  } else {
                                                    return eachu;
                                                  }
                                                });
                                                if (!departmentExist) {
                                                  users.push(value);
                                                }
                                                return {
                                                  ...eachW,
                                                  users,
                                                };
                                              }
                                            } else {
                                              return eachW;
                                            }
                                          });

                                          m_set("workflows", data);
                                          // handleSelectChange(value, 'dept', values.workflows, m_set)
                                        }}
                                        value={departmentName}
                                        options={departments}
                                        defaultOptions={departments}
                                        loadOptions={onSearchdDeptDebounce}
                                      />
                                      <CustomErrorMessage
                                        name="departmentId"
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      className="form-label"
                                      for="departmentId"
                                    >
                                      Designation
                                      <span className="required-star"> *</span>
                                    </label>
                                    <div className="form-control-wrap">
                                      <AsyncSelect
                                        className="sop-custom-form-select"
                                        id="departmentId"
                                        name="departmentId"
                                        placeholder="Select..."
                                        onChange={(value) => {
                                          setDesignationName(value);

                                          let data = m_values.workflows || [];
                                          data = data.map((eachW, index) => {
                                            if (index === selectedPosition) {
                                              let users = eachW?.users || [];
                                              if (!users.length) {
                                                return {
                                                  ...eachW,
                                                  users: [value],
                                                };
                                              } else {
                                                let designationExist = false;
                                                users = users.map((eachu) => {
                                                  if (eachu?.desgName) {
                                                    designationExist = true;
                                                    return value;
                                                  } else {
                                                    return eachu;
                                                  }
                                                });
                                                if (!designationExist) {
                                                  users.push(value);
                                                }
                                                return {
                                                  ...eachW,
                                                  users,
                                                };
                                              }
                                            } else {
                                              return eachW;
                                            }
                                          });

                                          m_set("workflows", data);
                                          // handleSelectChange(value, 'dept', values.workflows, m_set)
                                        }}
                                        value={designationName}
                                        options={designations}
                                        defaultOptions={designations}
                                        loadOptions={onSearchdDesigDebounce}
                                      />
                                      <CustomErrorMessage
                                        name="departmentId"
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <ItemsDroppable
                                    onDrag={onDragItem}
                                    setFieldValue={setFieldValue}
                                    dataType="Available"
                                    style={{ height: 500 }}
                                  >
                                    <h5>{selectionLabel}</h5>
                                    {selectionList &&
                                      selectionList.length > 0 &&
                                      selectionList.map((_, index) => (
                                        <EachDragItem
                                          props={_}
                                          type="availble"
                                        />
                                      ))}
                                  </ItemsDroppable>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex gap-2">
                              <Button
                                className="back-icon-btn-main"
                                onClick={() => {
                                  onManageWorkflow();
                                }}
                              >
                                <span>{squareBackIcon}</span>
                                <span className="ms-2">Back</span>
                              </Button>
                              <Button
                                className="theme-btn-main"
                                onClick={m_submit}
                              >
                                {buttonLoading ? <ButtonLoading /> : "Submit"}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
