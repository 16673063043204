import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "reactstrap";
import axios from "axios";
import MainServices from "../../services/MainServices";

export default function Workflows(props) {
  const [sopWorkFlows, setSopWorkFlows] = useState([]);
  const { getWorkFlowsByType } = MainServices();
  const [workflowAssign, setWorkflowAssign] = useState({});

  useEffect(() => {
    if (props?.publicId) {
      getSopWorkflows();
    }
    // eslint-disable-next-line
  }, [props?.publicId, props?.isStatusUpdate]);

  useEffect(() => {
    if (props.onWorkflowAssignUpdate) {
      props.onWorkflowAssignUpdate(workflowAssign);
    }
  }, [workflowAssign, props]);

  //apis
  const getSopWorkflows = async () => {
    const details = {
      // workFlowType: "instrument-category",
      sorting: [{ key: "id", direction: "asc" }],
    };
    await axios
      .all([getWorkFlowsByType(details, props?.publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : {};
            setWorkflowAssign(workflows);
            workflows = workflows.eventWorkflows
              ? workflows.eventWorkflows
              : [];
            setSopWorkFlows(workflows);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  return (
    <div>
      {sopWorkFlows && sopWorkFlows.length > 0 && (
        <div className="mt-5 doc-properties-details-main">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="doc-properties-header-main fw-bold">
                Workflow
              </span>
            </div>
          </div>

          <div className="workflows content">
            <div className="table-main mt-4">
              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head table-header-color">
                  <tr>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>
                      Workflow Name
                    </th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Department</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Designation</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>
                      Workflow Status
                    </th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Initiated On</th>
                    <th style={{ backgroundColor: "#d2e3ef" }}>Approved On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Initiator</td>
                    <td>{workflowAssign?.initiator?.empName}</td>
                    <td>{workflowAssign?.initiator?.deptartment?.deptName}</td>
                    <td>{workflowAssign?.initiator?.designation?.desgName}</td>
                    <td>Initiated</td>
                    <td>
                      {moment(workflowAssign?.initiator?.createdAt).format(
                        "DD MMM YYYY HH:mm"
                      )}
                    </td>
                    <td>-</td>
                  </tr>
                  {sopWorkFlows.map((each, index) => (
                    <tr key={index}>
                      <td>{index + 2}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {each?.workflowName}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {each?.user?.empName}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {each?.user
                          ? each?.user?.deptartment?.deptName
                          : each?.userDepartment?.deptName || "-"}
                      </td>
                      <td
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {each?.user
                          ? each?.user?.designation?.desgName
                          : each?.userDesignation?.desgName || "-"}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {each?.workflowStatus}
                      </td>
                      <td>
                        {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                      </td>
                      <td>{moment(each?.updatedAt).format("DD MMM YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
