import usePermission from "../../hooks/usePermission";
import useAuth from "../../hooks/useAuth";

export default function PermissionFunctions() {
  const { permissions } = usePermission();
  const { user } = useAuth();

  const functions = {
    grantPermission: (required_permission) => {
      if (user.role === "superadmin") {
        return true;
      } else {
        return permissions.includes(required_permission);
      }
      // return true;
    },
  };
  return functions;
}
