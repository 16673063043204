import React, { useState } from "react";
import { PrimaryInput } from "../../assets/FormElements";
import { Formik } from "formik";
import ButtonLoading from "../../assets/loaders/ButtonLoading";
import { Button, Form } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import * as Yup from "yup";
import CustomErrorMessage from "../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../assets/images/icons/icons";

export default function CustomDigitalSignature({
  digitalSignatureHandleSubmit,
  onDigitalSignatureModal,
  values: apiValues,
  isLoading
}) {
  const { user } = useAuth();
  const [buttonLoading, setButtonLoading] = useState(0);

  //schema
  const digitalSignatureSchema = Yup.object().shape({
    password: Yup.string()
      .transform((value) => value.trim())
      .required("Password is Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          email: user?.email || "",
          password: "",
        }}
        validationSchema={digitalSignatureSchema}
        onSubmit={(values) => {
          digitalSignatureHandleSubmit({ ...apiValues , ...values});
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <p className="mb-3 mt-0" style={{ fontWeight: 500 }}>
              Digital Signature :
            </p>
            <div className="form-group mt-3 col-12 ">
              <label className="mx-3 mb-1">Login Id</label>

              <div className="w-100 px-3">
                <PrimaryInput
                  placeholder="Enter Login Id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  type="text"
                  disabled={true}
                />
                <CustomErrorMessage
                  errors={errors}
                  touched={touched}
                  name="email"
                />
              </div>
            </div>
            <div className="form-group mt-3 col-12 ">
              <label className="mx-3 mb-1">Password</label>

              <div className="w-100 px-3">
                <PrimaryInput
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  type="password"
                />
                <CustomErrorMessage
                  errors={errors}
                  touched={touched}
                  name="password"
                />
              </div>
            </div>

            <div className="my-4" style={{ borderBottom: "1px dashed #000" }} />
            <div className="mt-3">
              <div className="d-flex gap-2">
                <Button
                  className="back-icon-btn-main"
                  onClick={() => {
                    onDigitalSignatureModal();
                  }}
                >
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <Button className="theme-btn-main" type="submit">
                  {isLoading ? <ButtonLoading /> : "Submit"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
