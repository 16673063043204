import { Formik } from "formik";
import React from "react";
import Select from "react-select";
import { Button } from "reactstrap";
import { PrimaryButton } from "../buttons";
import ButtonLoading from "../loaders/ButtonLoading";
import { squareBackIcon } from "../../assets/images/icons/icons";
import { PrimaryInput } from "../FormElements";
import * as Yup from "yup";
import CustomErrorMessage from "../elements/ErrorMessage";
import useAuth from "../../hooks/useAuth";

export default function StatusModalContent({
  statusApiFunction,
  onClose,
  setting,
  loading,
}) {
  let available_status = setting.available_status || [];
  available_status = available_status.map((each) => {
    return {
      label: each,
      value: each,
    };
  });

  const { user } = useAuth();
  const schemas = {
    withOutPassword: Yup.object().shape({
      status: Yup.mixed().required("Status Name is Required"),
    }),
    withPassword: Yup.object().shape({
      status: Yup.mixed().required("Status Name is Required"),
      email: Yup.string().email().required("Email  is Required"),
      password: Yup.string().required("Password  is Required"),
    }),
  };

  return (
    <div className="status-modal-content">
      <Formik
        initialValues={{ status: "", email: user?.email, password: "" }}
        validationSchema={
          setting.digital_signature
            ? schemas.withPassword
            : schemas.withOutPassword
        }
        onSubmit={(values) => {
          statusApiFunction(values, true);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className="row">
            <div className="form-group mt-3 col-12 ">
              <label className="mx-3 mb-1">Status</label>

              <div className="w-100 px-3">
                <Select
                  placeholder="Status"
                  onChange={(e) => {
                    setFieldValue(`status`, e);
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                  name="status"
                  options={available_status}
                />
                <CustomErrorMessage
                  errors={errors}
                  touched={touched}
                  name="status"
                />
              </div>
            </div>
            {setting.digital_signature && (
              <>
                <div className="form-group mt-3 col-12 ">
                  <label className="mx-3 mb-1">Email</label>

                  <div className="w-100 px-3">
                    <PrimaryInput
                      placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      type="text"
                      disabled={true}
                    />
                    <CustomErrorMessage
                      errors={errors}
                      touched={touched}
                      name="email"
                    />
                  </div>
                </div>
                <div className="form-group mt-3 col-12 ">
                  <label className="mx-3 mb-1">Password</label>

                  <div className="w-100 px-3">
                    <PrimaryInput
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name="password"
                      type="password"
                    />
                    <CustomErrorMessage
                      errors={errors}
                      touched={touched}
                      name="password"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="mt-3">
              <div className="d-flex gap-2 justify-content-end">
                <Button className="back-icon-btn-main" onClick={onClose}>
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <PrimaryButton
                  className="theme-btn-main"
                  onClick={handleSubmit}
                  title={loading ? <ButtonLoading /> : "Update"}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
