import React from "react";
import "./AuthLayout.scss";
import useTheme from "../../hooks/useTheme";

export default function Layout(props) {
  const { theme } = useTheme();
  return (
    <div className="no-auth-layout">
      <div className="content-layout">{props.children}</div>
    </div>
  );
}
