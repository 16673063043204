import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import { ConfigContext } from "../context/Config";
import useConfig from "../hooks/useConfig";

const initialState = {
  theme: "red",
};

const reducers = {
  THEMETOGGLE: (state, action) => {
    return { ...state, theme: action.payload };
  },
};

const reducer = (state, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;

const ThemeContext = createContext({
  ...initialState,
  themeToggle: () => Promise.resolve(),
});

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

function ThemeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const config = useConfig(ConfigContext);

  const themeToggle = (data) => {
    dispatch({ type: "THEMETOGGLE", payload: data });
  };

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        theme: config.theme,
        themeToggle,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
