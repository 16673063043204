import "./Sidebar.scss";
import { NavLink as RouterLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { icons } from "../../assets/images/icons/icons";
import ToolLogo from "../../assets/images/icons/icons8-projects-50.png";
import logo from "../../assets/images/icons/logo.svg";
import ArizonaLogo from "../../assets/images/icons/arizonaLogo.svg";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { addicon } from "../../assets/images/icons/icons";
import { sidebarbottomlogo } from "../../assets/images/icons/icons";
import usePermission from "../../hooks/usePermission";
import PermissionFunctions from "../../assets/functions/PermissionFunctions";
import useConfig from "../../hooks/useConfig";
import MainServices from "../../services/MainServices";
import LocationIcon from "../../assets/images/location.svg";
import CallIcon from "../../assets/images/call.svg";
import SmsIcon from "../../assets/images/sms.svg";

import { Formik } from "formik";
import useAuth from "../../hooks/useAuth";

export default function Sidebar({ setSidebarToggle }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menuToggle, setMenuToggle] = useState({});
  const [menus, setMenus] = useState([]);
  const { grantPermission } = PermissionFunctions();
  const { theme, source } = useConfig();
  const [applications, setApplications] = useState([]);
  const [tools, setTools] = useState([]);
  const { getToolsAccess, getToolsInformation } = MainServices();
  const {
    user: { role },
  } = useAuth();

  useEffect(() => {
    axios
      .get("menu.json")
      .then(function (res) {
        if (res?.data) {
          let resData = res.data;
          resData = resData.menus ? resData.menus : [];
          setMenus(resData);
        }
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred menu json");
      });
  }, []);

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const toggle = (type) => {
    if (type === "usermanagementicon")
      navigate({ pathname: "masters/branches" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });

    if (type === "trainingmanagementicon")
      navigate({ pathname: "documents/registration" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });
  };

  useEffect(() => {
    getToolMasterByStatus();
    getTools();
  }, []);

  const getToolMasterByStatus = async () => {
    const details = {
      pageSize: 20,
      pageNumber: 1,
    };
    await axios
      .all([getToolsAccess(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let location = resData.data ? resData.data : [];

            location = location.map((_, index) => {
              return {
                ..._,
              };
            });
            setApplications(location);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Location");
      });
  };

  const getTools = async () => {
    const details = {
      pageSize: 20,
      pageNumber: 1,
    };
    await axios
      .all([getToolsInformation(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData && resData.success) {
            const tools = resData.data ? JSON.parse(resData.data.content) : [];
            console.log("TOLLSs", tools);
            setTools(tools);
          } else {
            console.error("Failed to fetch tools data.");
          }
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Location");
      });
  };

  const [dashboardModel, setDashboardModel] = useState(false);
  const [countings, setCountings] = useState();

  const onOpenModel = (selected) => {
    setDashboardModel(!dashboardModel);
  };
  const [menuToggleTool, setMenuToggleTool] = useState(false);
  const handleMenuToggleTool = () => {
    setMenuToggleTool(!menuToggleTool);
  };

  return (
    <div className="sidebar-main">
      <div
        className={`nk-sidebar is-light ${theme}`}
        data-content="sidebarMenu"
      >
        <div className={`nk-sidebar-element nk-sidebar-head  ${theme}`}>
          <div className="nk-sidebar-brand himsidebar-logo">
            <div className="himsidebar-logo-main">
              <img src={source === "galfar" ? logo : ArizonaLogo} alt="logo" />
            </div>
          </div>
        </div>
        <hr
          style={{
            borderBottom: "2px solid #fff",
          }}
        />
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <SimpleBar className={`nk-sidebar-menu ${theme}`} autoHide={false}>
              <ul className="nk-menu sidebar-option-menu-main">
                {menus &&
                  menus.length > 0 &&
                  menus.map((each) => {
                    if (each.label === "Tasks" && role !== "admin") {
                      return null;
                    }

                    let isChildren = each.children && each.children.length > 0;
                    return (
                      grantPermission(each.required_permission) && (
                        <li
                          className={`nk-menu-item   ${
                            isChildren ? "has-sub " : ""
                          }${
                            isChildren && !menuToggle[each.icon]
                              ? "menu-sub-collapse "
                              : ""
                          }${pathname.indexOf(each.link) > -1 ? "active" : ""}`}
                        >
                          {each.children && each.children.length > 0 ? (
                            <>
                              <span
                                className="nk-menu-link nk-menu-toggle span-toggle"
                                onClick={() => toggle(each.icon)}
                              >
                                <span className="nk-menu-icon">
                                  {icons[each.icon]}
                                </span>
                                <span className="nk-menu-text">
                                  {each.label}
                                </span>
                              </span>

                              <ul
                                className="nk-menu-sub"
                                style={{
                                  display: menuToggle[each.icon]
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {each.children.map((_) => {
                                  return (
                                    grantPermission(_.required_permission) && (
                                      <li
                                        className={`nk-menu-item ${
                                          pathname === each.link + _.link
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <RouterLink
                                          to={{
                                            pathname: each.link + _.link,
                                          }}
                                          className="nk-menu-link"
                                        >
                                          <span className="nk-menu-text">
                                            {_.label}
                                          </span>
                                        </RouterLink>
                                      </li>
                                    )
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <RouterLink
                              to={each.link}
                              className={"nk-menu-link"}
                              onClick={() => setMenuToggle({})}
                            >
                              <span className="nk-menu-icon">
                                {icons[each.icon]}
                              </span>
                              <span className="nk-menu-text">{each.label}</span>
                            </RouterLink>
                          )}
                        </li>
                      )
                    );
                  })}
                <div className="dropdownTool">
                  <button
                    onClick={handleMenuToggleTool}
                    className={`button-tool ${
                      menuToggleTool ? "open" : "closed"
                    }`}
                  >
                    <div className="tool-icon">
                      <span className="toolbutton-icon">
                        <img src={ToolLogo} alt="" width="20px  " />
                      </span>
                      <span className="toolbutton-text">Tools</span>
                    </div>
                    <span className="button-arrow">
                      {menuToggleTool ? (
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 8L8 12H16L12 8Z" fill="#ffffff" />
                        </svg>
                      ) : (
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M16 12L12 16L8 12H16Z" fill="#ffffff" />
                        </svg>
                      )}
                    </span>
                  </button>
                  {menuToggleTool && (
                    <ul className="nk-menu mt-2">
                      {applications &&
                        applications.length > 0 &&
                        applications.map(
                          (site) =>
                            site.name !== "auth" && (
                              <li className="nk-menu-linkss">
                                {site.siteUrl ? (
                                  <a
                                    href={site.siteUrl}
                                    className="side-menu_links"
                                    onClick={() => setMenuToggle(false)}
                                  >
                                    {/* <span className="nk-menu-icon">
                                      {icons.link}
                                    </span> */}
                                    <span className="nk-menu-text">
                                      {site.name === "auth"
                                        ? "Authentication"
                                        : site.label}
                                    </span>
                                  </a>
                                ) : (
                                  <>
                                    <div
                                      className="side-menu_links2"
                                      onClick={() => {
                                        onOpenModel(site);
                                        setMenuToggle(false);
                                      }}
                                    >
                                      <a>
                                        {/* <span className="nk-menu-icon">
                                      {icons.link}
                                    </span> */}
                                        <span className="nk-menu-text">
                                          {site.name === "auth"
                                            ? "Authentication"
                                            : site.label}
                                        </span>
                                      </a>
                                    </div>
                                  </>
                                )}
                              </li>
                            )
                        )}
                    </ul>
                  )}
                </div>
                {/* {applications &&
                  applications.length > 0 &&
                  applications.map(
                    (site) =>
                      site.name !== "auth" && (
                        <li className={`nk-menu-item`}>
                          {site.siteUrl ? (
                            <a
                              href={site.siteUrl}
                              className={"nk-menu-link"}
                              onClick={() => setMenuToggle({})}
                            >
                              <span className="nk-menu-icon">{icons.link}</span>
                              <span className="nk-menu-text">
                                {site.name === "auth"
                                  ? "Authentication"
                                  : site.label}
                              </span>
                            </a>
                          ) : (
                            <div
                              className="nk-menu-link"
                              onClick={() => onOpenModel(site)}
                            >
                              <span className="nk-menu-icon">{icons.link}</span>
                              <span className="nk-menu-text">
                                {site.name === "auth"
                                  ? "Authentication"
                                  : site.label}
                              </span>
                            </div>
                          )}
                        </li>
                      )
                  )} */}
                <hr
                  style={{
                    borderBottom: "2px solid #fff",
                  }}
                />
                <RouterLink className="nk-menu-link" onClick={onLogout}>
                  <div className="mx-3" style={{ marginBottom: "70px" }}>
                    {sidebarbottomlogo}
                  </div>
                </RouterLink>{" "}
              </ul>
            </SimpleBar>
          </div>

          {/* <Modal
            centered
            show={dashboardModel}
            onHide={onOpenModel}
            className="coming-soon-modal"
          >
            {dashboardModel && (
              <Modal.Body className="coming-soon-modal-body">
                <div className="coming-soon-title-area">
                  <div className="coming-soon-text">
                    <p>{countings?.name}</p>
                    <h5>Coming Soon</h5>
                  </div>
                  <div className="coming-soon-ok-btn">
                    <Button
                      className="coming-soon-ok-btn-main"
                      onClick={onOpenModel}
                    >
                      <span className="ms-2">Ok</span>
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            )}
          </Modal> */}
          <Modal
            centered
            show={dashboardModel}
            onHide={onOpenModel}
            className="coming-soon-modal"
            size="lg"
          >
            <Formik
              initialValues={{
                terms_and_conditions:
                  (tools?.content && JSON.parse(tools?.content)) || [],
              }}
            >
              {dashboardModel && (
                <Modal.Body className="coming-soon-modal-body">
                  <div className="coming-soon-title-area">
                    <div className="header">
                      <h5>For access contact us </h5>
                      <p>
                        Experience the power of automation and robust security
                        firsthand. Request a demo today and transform the way
                        you work.
                      </p>
                      <p className="contact_us">Contact Us :</p>
                      <img src={tools.logo} alt="" className="short-img" />
                      <div className="location">
                        <div className="location-div">
                          <img src={LocationIcon} alt="" />
                        </div>
                        <p className="loccation-text">Address :</p>
                        <p className="location-span">{tools.address}</p>
                      </div>
                      <div className="location">
                        <div className="location-div">
                          <img src={CallIcon} alt="" />
                        </div>
                        <p className="loccation-text">Contact Number :</p>
                        <p className="location-span">{tools.contact_number}</p>
                      </div>
                      <div className="location">
                        <div className="location-div">
                          <img src={SmsIcon} alt="" />
                        </div>
                        <p className="loccation-text">Email Address :</p>
                        <p className="location-span">{tools.email_address}</p>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              )}
            </Formik>
          </Modal>
        </div>
      </div>
    </div>
  );
}
