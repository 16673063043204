import { useDrag } from "react-dnd";
import { ItemTypes } from "../../utils/ItemTypes";
import UserIcon from "../../assets/images/icons/profile-image.svg";

export default function EachDragItem({ props, type }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: props,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        dropResult.onDragItem({
          item,
          type: dropResult.type,
          setFieldValue: dropResult.setFieldValue,
        });
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });
  const styles = {
    padding: "4px 8px",
    cursor: "pointer",
    background: isDragging ? "#cccccc70" : "#ccc",
    color: "#000",
    marginBottom: "5px",
    borderRadius: 2,
  };
  return (
    <div key={props._id} _id={props._id} ref={drag} style={styles}>
      <div className="d-flex gap-3 p-1">
        <img src={UserIcon} width={50} alt="user icon" />
        <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "500" }}>
          {props?.label}
          <p className="m-0 p-0" style={{ fontSize: "12px" }}>
            {
              props?.empCode || "-"
            }
          </p>
          <p className="m-0 p-0" style={{ fontSize: "12px" }}>
            {
              props?.empEmail || "-"
            }
          </p>
        </p>{" "}
      </div>
      <div className="d-flex">
        <p style={{ marginBottom: 0 }}>{props?.department?.department_name}</p>
        <p style={{ marginBottom: 0 }}>
          {props?.designation?.designation_name}
        </p>
      </div>
    </div>
  );
}
