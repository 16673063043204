import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import moment from "moment";
import PageLoader from "../../../../assets/loaders/PageLoading";
import ApprovalStatusModal from "../../../../assets/modal/ApprovalStatusModal";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import { Button, Modal } from "react-bootstrap";
import { Table } from "reactstrap";
import "./DepartmentDetails.scss";
import Back_Arrow from "../../../../assets/images/icons/arrow-left.svg";
import PermissionFunctions from "../../../../assets/functions/PermissionFunctions";
import editicon from "../../../../assets/images/icons/edit.svg";
import { InputGroup, Form } from "react-bootstrap";
import { PrimaryInput } from "../../../../assets/FormElements";
import { Formik } from "formik";
import useAuth from "../../../../hooks/useAuth";
import useFeatureSetting from "../../../../hooks/useFeatureSetting";
import * as Yup from "yup";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../../assets/images/icons/icons";
import { PrimaryButton } from "../../../../assets/buttons";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import Cross from "../../../../assets/images/icons/cross.svg";
import IsWorkflowSlug from "../../../../assets/modal/IsWorkflowSlug";
import CustomDigitalSignature from "../../../../assets/modal/CustomDigitalSignature";

const DepartmentDetails = () => {
  const { user } = useAuth();
  const { updateCurrentPage } = useCurrentPage();
  const {
    getByDepartmentById,
    updateEventWorkflowReview,
    getEventWorkflowById,
    getWorkFlowsByType,
    getWorkFlowHistoryByType,
    updateDepartmentMaster,
    getWorkflowsBySlug,
  } = MainServices();
  const [pageLoading, setPageLoading] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [workflowMasters, setWorkflowMasters] = useState({});
  const location = useLocation();
  const [approvedLoading, setApprovedLoading] = useState(0);
  const [approvedModal, setApprovedModal] = useState(false);
  const [sopWorkFlows, setSopWorkFlows] = useState([]);
  const [workFlowHisories, setWorkFlowHisories] = useState([]);
  const [reinitiateClicked, setReinitiateClicked] = useState(false);
  const { grantPermission } = PermissionFunctions();
  //url Params
  const publicId = new URLSearchParams(location.search).get("publicId");
  const refId = new URLSearchParams(location.search).get("refId");
  const workflowConcurrencystamp = new URLSearchParams(location.search).get(
    "workflowConcurrencystamp"
  );
  const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const { updateSettingContext, setting } = useFeatureSetting();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
  });
  const edit = new URLSearchParams(location.search).get("edit");
  const [editInitiated, setEditInitiated] = useState(false);
  const [workflowAssign, setWorkflowAssign] = useState({});
  const [intiLoading, setIntiLoading] = useState(0);
  const [isWorkflowFlag, setIsWorkflowFlag] = useState({});
  const [workflowDataSet, setWorkflowDataSet] = useState("");
  const [workflowModal, setWorkflowModal] = useState(false);
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false);

  const departmentSchema = Yup.object().shape({
    deptName: Yup.string()
      .transform((value) => value.trim())
      .required("Department Name is Required"),
    deptCode: Yup.string()
      .transform((value) => value.trim())
      .required("Department Code is Required"),
  });

  const updateDepartmentSchema = Yup.object().shape({
    deptName: Yup.string()
      .transform((value) => value.trim())
      .required("Department Name is Required"),
    deptCode: Yup.string()
      .transform((value) => value.trim())
      .required("Department Code is Required"),
    workflows: Yup.array()
      .of(
        Yup.object().shape({
          users: Yup.array().required('users array is required'),
        })
      )
      .test('at-least-one-user', 'Workflow is Required', function (workflows) {
        return workflows.some(workflow => workflow.users && workflow.users.length > 0);
      })
    // password: Yup.string().transform((value) => value.trim()).required("Password is Required"),
  });

  useEffect(() => {
    updateCurrentPage({
      title: "Department",
      subtitle: "Details",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSettingContext("deptartments");
  }, []);

  useEffect(() => {
    if (publicId) {
      getDepartmentMastersByStatus();
      getSopWorkflows();
      getWorkflowHistory();
      getIsWorkflowSlug();
    }
    // eslint-disable-next-line
  }, [publicId]);

  useEffect(() => {
    if (refId) {
      getWorkflowById();
    }
    // eslint-disable-next-line
  }, [refId]);

  const updateDepartment = async (values) => {
    setButtonLoading(true);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    const details = {
      deptName: values.deptName,
      deptCode: values.deptCode,
      deptDescription: values.deptDescription,
      ...(values?.remark && { remark: values?.remark }),
      ...(values?.password && { password: values?.password }),
    };
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([
        updateDepartmentMaster(
          details,
          selectedDepartment.publicId,
          selectedDepartment.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDepartmentMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
            });
            onAddDepartment(false);
            setDigitalSignatureModal(false);
            values?.status === "approved" && navigate(-1);
            notifySuccess("Department Master Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Department Master");
      });
  };

  const getDepartmentMastersByStatus = async () => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getByDepartmentById(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData.data ? resData.data : {};
            setDepartments(departments);
            if (edit === "true" && !editInitiated) {
              onAddDepartment(departments);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
  };

  const getWorkflowById = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: refId }],
    };

    await axios
      .all([getEventWorkflowById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : [];
            setWorkflowMasters(workflows[0]);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Event Workflow");
      });
  };

  const updateWorkflowStatus = async (values) => {
    setApprovedLoading(true);
    const details = {
      workflowStatus: values.status?.value,
      //   email: values.user_id,
      ...(values?.password && { password: values.password }),
      comments: values.comment,
    };
    await axios
      .all([
        updateEventWorkflowReview(details, refId, workflowConcurrencystamp),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("Status changed successfully");
            setApprovedLoading(false);
            onApprovedStatusModal();
            getDepartmentMastersByStatus();
            getWorkflowById();
            getSopWorkflows();
            getWorkflowHistory();
          } else {
            message && notifyWarn(message);
            setApprovedLoading(false);
          }
        } else {
          setApprovedLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in status update");
      });
  };

  const getSopWorkflows = async () => {
    const details = {
      workFlowType: "department",
      sorting: [{ key: "id", direction: "asc" }],
    };
    await axios
      .all([getWorkFlowsByType(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : {};
            setWorkflowAssign(workflows);
            workflows = workflows.eventWorkflows
              ? workflows.eventWorkflows
              : [];
            setSopWorkFlows(workflows);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  const getWorkflowHistory = async () => {
    const details = {
      sorting: [{ key: "id", direction: "desc" }],
    };
    await axios
      .all([getWorkFlowHistoryByType(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflowHistory = resData.data ? resData.data : [];
            setWorkFlowHisories(workflowHistory);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting workflows");
      });
  };

  const updateDepartmentMasterIntiatad = async (values) => {
    setButtonLoading(true);
    const details = {
      deptName: values.deptName,
      deptCode: values.deptCode,
      deptDescription: values.deptDescription,
      ...(values?.password && { password: values?.password }),
      reinitiateWorkflowAssignId: workflowAssign.publicId,
      status: "reinitiated",
    };
    await axios
      .all([
        updateDepartmentMaster(
          details,
          selectedDepartment.publicId,
          selectedDepartment.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDepartmentMastersByStatus({}, publicId);
            onAddDepartment();
            setReinitiateClicked(false);
            setDigitalSignatureModal(false);
            // setIsStatusUpdate(Math.random());
            notifySuccess("Department Master Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Department Master");
      });
  };

  const getIsWorkflowSlug = async () => {
    const details = {};
    await axios
      .all([getWorkflowsBySlug(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let isWorkflow = resData?.data ? resData?.data : {};
            isWorkflow = isWorkflow?.content
              ? JSON.parse(isWorkflow?.content)
              : {};
            setIsWorkflowFlag(isWorkflow);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting measurement types");
      });
  };

  //functions
  const onManageWorkflow = ({ values, setFieldValue }) => {
    console.log(values);
    setWorkflowDataSet({
      workflows: values?.workflows,
      setFieldValue,
    });
    setWorkflowModal((prev) => !prev);
  };

  const onApprovedStatusModal = () => {
    setApprovedModal(!approvedModal);
  };

  const onAddDepartment = (selected) => {
    if (selected) {
      setSelectedDepartment(selected);
    } else {
      setSelectedDepartment("");
    }
    setAddDepartmentModal(!addDepartmentModal);
  };

  const onDigitalSignatureModal = () => {
    setDigitalSignatureModal(!digitalSignatureModal);
  };

  const navigate = useNavigate();

  return (
    <div className="department-details-main">
      <div className="admin-popular-main">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="header">
            <p className="content-header-main mb-0">Department Details</p>
          </div>
          {refId && workflowMasters?.workflowStatus === "pending" && (
            <Button onClick={onApprovedStatusModal}>Update Status</Button>
          )}
        </div>
        <div className="mb-3">
          <img
            src={Back_Arrow}
            alt="backArrow"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {pageLoading ? (
          <PageLoader />
        ) : departments ? (
          <div className="content">
            <div className="table-main">
              {grantPermission("Department_update") &&
                (departments?.status === "approved" ||
                  departments?.status === "rejected") &&
                departments?.recordStatus === "active" && (
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex justify-content-end mb-3"
                  >
                    <img
                      src={editicon}
                      alt="Edit icon"
                      onClick={() => onAddDepartment(departments)}
                    />
                  </div>
                )}
              <div className="row details-row">
                <div className="col-md-6 col-head fw-bold">Name</div>
                <div className="col-md-6 ">{departments?.deptName}</div>

                <div className="col-md-6 col-head fw-bold">Code</div>
                <div className="col-md-6 ">{departments?.deptCode}</div>

                <div className="col-md-6 col-head fw-bold">Created At</div>
                <div className="col-md-6 ">
                  {moment(departments?.createdAt).format("DD-MM-YYYY")}
                </div>

                <div className="col-md-6 col-head fw-bold">Status</div>
                <div className="col-md-6 ">
                  {departments?.status === "approved"
                    ? departments?.recordStatus
                    : departments?.status}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Department Found</div>
        )}

        {sopWorkFlows && sopWorkFlows.length > 0 ? (
          <div className="mt-5 doc-properties-details-main">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="doc-properties-header-main fw-bold">
                  Workflow
                </span>
              </div>
            </div>

            <div className="workflows content">
              <div className="table-main mt-4">
                <Table responsive bordered className="table table-bordered">
                  <thead className="service-table-head table-header-color">
                    <tr>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No.</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Name
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Status
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Initiated On
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Approved On
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sopWorkFlows.map((each, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.user?.empName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowStatus}
                        </td>
                        <td>
                          {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                        </td>
                        <td>
                          {moment(each?.updatedAt).format("DD MMM YYYY HH:mm")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {workFlowHisories && workFlowHisories.length > 0 ? (
          <div className="mt-5 doc-properties-details-main">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="doc-properties-header-main fw-bold">
                  Workflow History
                </span>
              </div>
            </div>

            <div className="workflows content">
              <div className="table-main mt-4">
                <Table responsive bordered className="table table-bordered">
                  <thead className="service-table-head table-header-color">
                    <tr>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Sr.No.</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Date & Time
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Name
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>User</th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>
                        Workflow Status
                      </th>
                      <th style={{ backgroundColor: "#d2e3ef" }}>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workFlowHisories.map((each, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {moment(each?.createdAt).format("DD MMM YYYY HH:mm")}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.user?.empName}
                        </td>
                        <td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {each?.workflowStatus}
                        </td>
                        <td>{each?.comments}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        centered
        show={addDepartmentModal}
        onHide={onAddDepartment}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {selectedDepartment && selectedDepartment?.publicId ? "Update Department" : "Add New Department"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please enter department name and select the head of Department.
          </p>
          <Formik
            initialValues={{
              deptName: selectedDepartment?.deptName || "",
              deptCode: selectedDepartment?.deptCode || "",
              deptDescription: selectedDepartment?.deptDescription || "",
              workflow_name: selectedDepartment?.name || "",
              workflows: selectedDepartment?.workflowData || [
                {
                  workflow: "reviewer",
                  users: [],
                  no_of_approvals: "",
                },
                {
                  workflow: "approver",
                  users: [],
                  no_of_approvals: "",
                },
              ],
              disabled:
                selectedDepartment?.disabled === false ||
                selectedDepartment?.disabled === "No"
                  ? true
                  : false,
            }}
            validationSchema={
              setting?.workflow_required === 'true'
                ? updateDepartmentSchema
                : departmentSchema
            }
            onSubmit={(values) => {
              if (setting.digital_signature) {
                setSelectedDepartment({ ...selectedDepartment, ...values });
                onDigitalSignatureModal();
              } else {
                updateDepartment(values);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Department Name
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Department Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deptName}
                    name="deptName"
                    id="deptName"
                  />
                  <CustomErrorMessage
                    name="deptName"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Department Code
                    <span className="required-star"> *</span>
                  </label>
                  <PrimaryInput
                    placeholder="Enter Department Code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.deptCode}
                    name="deptCode"
                    id="deptCode"
                  />
                  <CustomErrorMessage
                    name="deptCode"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="form-group mt-3">
                  <label className="form-label" for="contact_number">
                    Department Description
                    <span className="required-star"> *</span>
                  </label>
                  <textarea
                    className="form-control employee-custom-form-control"
                    placeholder="Enter Department Description"
                    aria-label="With textarea"
                    name="deptDescription"
                    id="deptDescription"
                    value={values.deptDescription}
                    onChange={handleChange}
                  ></textarea>
                  <CustomErrorMessage
                    name="deptDescription"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                {isWorkflowFlag &&
                  isWorkflowFlag?.standard_workflow === "false" &&
                  setting?.workflow_disabled === "false" &&
                  selectedDepartment?.status === "approved" && (
                    <>
                      <div
                        className="my-4"
                        style={{ borderBottom: "1px dashed #000" }}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0" style={{ fontWeight: 500 }}>
                          Manage Workflow :
                            {setting?.workflow_required === "true" && 
                              (<span style={{ color: "#f00" }}> *</span>)}
                        </p>
                        <Button
                          className="theme-btn-main"
                          style={{ padding: "11px 12px" }}
                          onClick={() =>
                            onManageWorkflow({
                              values,
                              setFieldValue,
                            })
                          }
                        >
                          Manage Workflow
                        </Button>
                      </div>
                      <div className="workflows-list">
                        {values.workflows &&
                          values.workflows.length > 0 &&
                          values.workflows.map((each_work, index) => {
                            // Generate the options array for the number of approvals
                            const options = Array.from(
                              {
                                length:
                                  each_work?.users &&
                                  each_work?.users.length > 0 &&
                                  each_work?.users.length,
                              },
                              (_, i) => ({
                                label: `${i + 1}`,
                                value: i + 1,
                              })
                            );

                            return (
                              <div className="each-work-flow mt-3" key={index}>
                                <div className="d-flex justify-content-between">
                                  <div className="workflow-head">
                                    <span
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {each_work.workflow}
                                    </span>
                                  </div>
                                  <div className="form-group mb-1 d-flex gap-2 align-items-center">
                                    <label
                                      className="form-label mb-0"
                                      htmlFor="category"
                                    >
                                      {`Select Number of ${each_work.workflow}`}
                                      <span style={{ color: "#f00" }}> *</span>
                                    </label>
                                    <div className="form-control-wrap">
                                      <select
                                        className="sop-custom-form-select"
                                        id={`workflows.${index}.no_of_approvals`}
                                        name={`workflows.${index}.no_of_approvals`}
                                        placeholder="Select"
                                        onChange={(option) => {
                                          setFieldValue(
                                            `workflows.${index}.no_of_approvals`,
                                            option?.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        style={{
                                          width: "100px",
                                          fontSize: "12px",
                                          padding: "6px",
                                        }}
                                      >
                                        <option>Select...</option>
                                        {options &&
                                          options?.length > 0 &&
                                          options.map((option) => {
                                            return (
                                              <option value={option.value}>
                                                {option.label}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <CustomErrorMessage
                                        name={`noOfApprovals_${index}`}
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="users mt-2 px-3">
                                  {each_work.users &&
                                    each_work.users.length > 0 &&
                                    each_work.users.map((user, subI) => (
                                      <div
                                        className="each-user d-flex justify-content-between mt-2"
                                        key={subI}
                                      >
                                        <div>
                                          <span>{subI + 1}.</span>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {user.label}
                                          </span>
                                        </div>
                                        <div>
                                          <img
                                            alt="Cross"
                                            src={Cross}
                                            className="delete-topic"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              let data =
                                                values?.workflows || [];
                                              let users =
                                                data[index].users || [];
                                              users = users.filter(
                                                (each, i) => subI !== i
                                              );
                                              data[index].users = users;
                                              setFieldValue("workflows", data);
                                              setFieldValue(
                                                `workflows.${index}.no_of_approvals`,
                                                ""
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* {values.workflows &&
                                values.workflows.length > 0 &&
                                values.workflows[0]?.users &&
                                values.workflows[0]?.users.length === 0 && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      width: "200px",
                                      height: "200px",
                                      backgroundColor: "white",
                                      padding: "5px",
                                    }}
                                  >
                                    Will Appear Here
                                  </div>
                                )} */}
                    </>
                  )}

                {!digitalSignatureModal && (
                  <div className="mt-3">
                    <div className="d-flex gap-2">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onAddDepartment}
                      >
                        <span>{squareBackIcon}</span>
                        <span className="ms-2">Back</span>
                      </Button>
                      <PrimaryButton
                        className="theme-btn-main"
                        type="submit"
                        disabled={!dirty}
                        title={buttonLoading ? <ButtonLoading /> : "Submit"}
                      />
                      {selectedDepartment?.status === "rejected" && (
                        <Button
                          className="theme-btn-main"
                          onClick={() => {
                            if (setting.digital_signature) {
                              setSelectedDepartment((prev) => ({ ...prev, ...values }));
                              onDigitalSignatureModal();
                              setReinitiateClicked(true);
                            } else {
                              updateDepartmentMasterIntiatad(values);
                            }
                          }}
                          disabled={!dirty}
                          // onClick={() => {
                          //   updateDepartmentMasterIntiatad(values);
                          // }}
                        >
                          {intiLoading ? (
                            <ButtonLoading />
                          ) : selectedDepartment ? (
                            "Submit & Reinitiate"
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={approvedModal}
        onHide={onApprovedStatusModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Status
          </Modal.Title>
        </Modal.Header>

        {approvedModal && (
          <Modal.Body className="add-department-modal-body">
            <ApprovalStatusModal
              statusApiFunction={updateWorkflowStatus}
              onClose={onApprovedStatusModal}
              loading={approvedLoading}
            />
          </Modal.Body>
        )}
      </Modal>

      <Modal
        centered
        show={workflowModal}
        onHide={() => onManageWorkflow({})}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Manage Workflow
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <IsWorkflowSlug
            onManageWorkflow={() => onManageWorkflow({})}
            workflowDataSet={workflowDataSet}
          />
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={digitalSignatureModal}
        onHide={onDigitalSignatureModal}
        className="add-department-modal-main"
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            E - Signature
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <CustomDigitalSignature
            digitalSignatureHandleSubmit={reinitiateClicked ? updateDepartmentMasterIntiatad : updateDepartment}
            onDigitalSignatureModal={onDigitalSignatureModal}
            values={selectedDepartment}
            isLoading={reinitiateClicked ? intiLoading : buttonLoading}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DepartmentDetails;
