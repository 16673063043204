let apiServices = {
  //file_upload apis
  uploadFile: "file",

  //User Login
  userSignin: "login",
  userGoogleSignin: "auth/google",
  userFacebookSignin: "auth/facebook",
  getUserDetails: "get-user-details-by-token",

  //RolesPermissionByRole
  getRolePermissionsByRoles: "role-user-permission",

  //Document apis
  getDocumentMasters: "document",
  addDocumentMaster: "document",
  getDocumentByIdMaster: "documentById",
  getDocumentAccessMasters: "document-access",
  addDocumentAccessMaster: "document-access",
  deleteDocumentAccessMaster: "document-access-status-update",
  updateDocumentStatus: "document-status-update",

  //branch apis
  getBranchMasters: "branch",
  addBranchMaster: "branch",
  updateBranchMaster: "branch",
  deleteBranchMaster: "branch-status-update",
  getBranchById: "branch-by-id",
  getPlantById: "plant-by-id",
  getDivisionById: "division-by-id",

  //Doctype atart
  doctypeMasters: "doc-type",
  deleteDoctypeMaster: "doc-type-status-update",

  //audit trail
  getAuditTrails: "audit-trail",

  //department apis
  getDepartmentMasters: "deptartment",
  addDepartmentMaster: "deptartment",
  updateDepartmentMaster: "deptartment",
  deleteDepartmentMaster: "deptartment-status-update",
  getByDepartmentById: "department-by-id",

  //designation apis
  getDesignationMasters: "designation",
  addDesignationMaster: "designation",
  updateDesignationMaster: "designation",
  deleteDesignationMaster: "designation-status-update",
  getDesignationById: "designation-by-id",

  //employee apis
  getEmployeeMasters: "employe",
  addEmployeeMaster: "employe",
  updateEmployeeMaster: "employe",
  deleteEmployeeMaster: "employe-status-update",
  employeeStatusUpdateMaster: "employe-status-update",
  getEmpProfile: "employe-profile",
  resetpassword: "employe-reset-password",

  //Access Right apis
  getAccessRightMasters: "access-group-master",
  addAccessRightMaster: "access-group-master",
  updateAccessRightMaster: "access-group-master",
  deleteAccessRightMaster: "access-group-master-status-update",

  //Role Permission Apis
  getOpertationsByStatus: "operation",
  getRolePermissionMasters: "role-permission",
  getRoleMasters: "get-role",
  addRolePermissionMaster: "role-permission-save",
  updateRolePermissionMaster: "role-permission-update",
  deleteRolePermissionMaster: "role-permission-status-update",
  roleStatusUpdate: "role-status-update",
  getAllPermission: "permission",

  //AccessRole
  getAccessRoleById: "user/get_access_role_master",
  updateAccessRole: "rolePermission/update_role_and_permission",
  deleteAccessRole: "rolePermission/update_role_and_permission_status",
  getAccessRoleStatsByStatus: "user/get_access_role_master_stats_by_status",

  //Roles
  getRoles: "role/get_role_by_status",

  //Security Groups
  addSecurityGroupMaster: "security-group",
  getSecurityGroupMaster: "security-group",
  updateSecurityGroupMaster: "security-group",
  deleteSecurityGroupMaster: "security-group-status-update",
  getSecurityGroupPermissionMasters: "security-group-permission",
  addSecurityGroupPermissionMaster: "security-group-permission-save",
  updateSecurityGroupPermissionMaster: "security-group-permission-update",
  deleteSecurityGroupPermissionMaster:
    "security-group-permission-status-update",
  //Roles api
  getRolesByStatus: "role/get_role_by_status",

  //tools
  getToolsAccess: "tool-access",

  //feature-setting
  getSettings: "settings",
  featureSetting: "setting",
  updateSettingStatus: "update-setting-status",
  getSettingBySlug: "setting-by-slug",

  //plant apis
  getPlants: "plant",
  addPlant: "plant",
  updatePlant: "plant",
  deletePlant: "plant-status-update",

  //division apis
  addDivisionMaster: "division",
  getDivisionMaster: "division",
  updateDivision: "division",
  deleteDivisionMaster: "division-status-update",

  //tools api
  getToolsInformation: "new-settings/tool-access-information",
  //passwordPolicyapi
  getUserSetting: "application-settings",
  updatePolicy: "application-settings",

  //IDC Masters API
  getIDCMasters: "workflow",
  AddIDCMasters: "workflow",
  deleteIDCMaster: "workflow-update-status",
  updateIDCMaster: "workflow",

  //workflowgroup
  getWorkflowGroup: "workflow-group",
  addWorkflowGroup: "workflow-group",
  getWorkflowGroupById: "workflow-group-by-id",
  updateWorkflowGroup: "workflow-group",
  deleteWorkflowGroup: "workflow-group-status-update",

  //Dashboard Workflows api
  getEventWorkflowReview: "event-workflow-review",
  getWorkfowsPaginationCount: "get-all-event-workflow-review",
  updateEventWorkflowReview: "event-workflow-comment",
  getEventWorkflowById: "event-workflow",
  getWorkFlowsByType: "workflow-assign-type-id",
  getWorkFlowHistoryByType: "get-event-workflow-history",

  //workflow slug
  getWorkflowsBySlug: "new-settings",
};

export default apiServices;
